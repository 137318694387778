import 'bootstrap/dist/css/bootstrap.css';
import React,{useEffect} from 'react';
import './ItemRecipePopUp.css';

function ItemRecipePopUp(props) {

  const ChangeQty = (Item,sign) => {
    const newItemObj = { ...props.itemObj };
  const newRecipes = newItemObj.itemRecipes.map(recipe => ({ ...recipe }));
  // Find the selected item within the recipes
  const selectedItem = newRecipes.find(it => it.itemID === Item.itemID);
  // Update the quantity based on the sign parameter
  if (selectedItem) 
  {
    if(sign == '+')
    selectedItem.itemQty += 1; // Or any other logic based on the sign parameter
  else {
    if(selectedItem.itemQty > 0)
    selectedItem.itemQty -= 1; // Or any other logic based on the sign parameter
  else
  selectedItem.itemQty = 0;
  }
  }
  // Update the item recipes in the copied item object
  newItemObj.itemRecipes = newRecipes;
  newItemObj.itemQty =  newRecipes.reduce((total, Obj) => total += Obj["itemQty"], 0)
  // Update the state with the new item object
  props.setitemObj(newItemObj);
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return ()=> document.body.style.overflow = 'unset';
 }, [])

    return (
      <div className='card popup2 scrollable' id='popup2' style={{width:"90%",background:"#e1e1e1"}}>
<div className='container-fluid'>
<div className='row card-header p-0' style={{height: "50px",textAlign:"center",alignItems:"center",justifyContent:"center"}}>
<p className='fontmaaray' style={{margin:"0px",color:"black",fontSize:"24px"}}>
 {props.itemObj.itemName} 
 </p>
</div>
<div className='container-fluid ' >

<div style={{padding:"0px"}}>
<p className='fontmaaray' style={{fontSize:"35px",marginTop:"7px",fontWeight:"bold",direction:"rtl"}}>
{props.itemObj.itemQty} </p>
</div>

<div className='row g-2' style={{justifyContent:"center"}}>

{props.itemObj.itemRecipes.map((it, index) =>
  <div class="col-sm-2 col-sm-2 col-lg-4 col-6"  key = {it.itemID}>
                     <div class="shadow  rounded d-flex flex-column justify-content-center px-2 align-items-center
                      card-body" style={{ backgroundColor: "white",direction:'rtl',height:"100%" }}>
                       {/*design to card */}
                       <div className='card-body details' style={{width:"100%"}}>
                         <h5 className='card-title pt-2 '  id='cardname' 
                         style={{ "caret-color": "transparent",'color': "black",fontSize:"30px",minHeight:"50%" }} >
                         {it.prepareItemExtraName}
                         </h5>
                           <div className=" divbody mt-0">
                           <h4 className='price' id='price' style={{ color: "" }}> 
                            {it.itemDiscountedPrice}
                            </h4><span className='price '>جم</span> 
                         </div>
                       </div>
                       <div className=' w-100 btn d-flex justify-content-between my-1 ' 
                        style={{ 'background': "DarkOrange" }}>
                         <div className=' fs-5 text-white' >
                           <span className='margespan ' onClick={() => ChangeQty(it,'+')}>&#43;</span>
                         </div>
                         <div className='fs-5 text-white ' style={{direction:"ltr"}} id='quantity' onClick={() => ChangeQty(it,'+')}>
                         {typeof it.itemQty === 'number' ? it.itemQty.toFixed(2) : it.itemQty}
                         </div>
                         <div className=' text-white fs-5' onClick={() => ChangeQty(it,'-')}>
                           &#8722;</div>
                       </div>
                     </div>
                   
                   </div>
)}
<div className='row footerbtn ' >

<div className='col-6 col-sm-4 col-md-4 col-lg-6'  onClick={() => props.closepopupofaddone()}>
<button className='btn btn-danger w-100 fontmaaray' style={{ height: "50px"}}>الغاء</button>
</div>
<div className='col-6 col-sm-4 col-md-4 col-lg-6' onClick={() => props.AcceptData()}>
<button className='btn w-100 btn-success fontmaaray' style={{ height: "50px"}}>اضافه</button>
</div>

</div>
</div>

</div>
</div>
</div>
      );
            
    };
   
  export default  ItemRecipePopUp;
  