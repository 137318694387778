import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import './container.css';
import { BsCart2 } from "react-icons/bs";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { IoBackspaceSharp } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import Swal from "sweetalert2";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useMediaQuery } from 'react-responsive';
import { RxDotFilled } from "react-icons/rx";
import ItemRecipePopUp from "../ItemRecipePopUp/ItemRecipePopUp.js";
import { useRef } from 'react';
import $ from 'jquery';

function BasicExample(props) {
  const FnumDecimal = parseFloat("0.125").toFixed(2)
  const SnumDecimal = parseFloat("0.25").toFixed(2)
  const THnumDecimal = parseFloat("0.33").toFixed(2)
  const FOnumDecimal = parseFloat("0.5").toFixed(2)
  const safeDocument = typeof document !== 'undefined' ? document : {};
  const [itemObj, setitemObj] = React.useState();

  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;
  ///////////data of order to send to form ///////
  const objtotal = {
    'totalqut': 0,
    'totalpricedel': 0,
    'totaldiscount': 0,
    'totalprice': 0,
    'Nettotal': 0
  }
  const [total, settotal] = React.useState(objtotal);
  //////////////////////function to increase button and update the value of quantity /////////////////
  const ChangeQty = (Item, Sign) => {
    // Update state with incremented value
    if (Sign == '+')
      var quantity = parseFloat(Item.itemQty) + 1;
    else if (Sign == '-')
      var quantity = parseFloat(Item.itemQty) - 1;
    else if (Sign == '0')
      var quantity = 0;
    if (quantity > Item.itemMaximumOrderQty && Item.itemMaximumOrderQty > 0) {
      // +Item.itemMaximumOrderQty
      Swal.fire("  لقد وصلت الي اقصي كميه لهذا الصنف")
      quantity = Item.itemMaximumOrderQty;
    }

    else if (quantity < 0) {
      alert("تم حذف الصنف من قائمتك يمكن اعاده اختياره ")
      quantity = 0;
    }

    Item.itemQty = quantity;
    orderdata(Item);
  }

  ////////////////order list /////////////////
  const zeroitem = (Item) => {
    // Update state with incremented value

    setpopupprice(false)
    Item.itemQty = 0;
    orderdata(Item);
  }
  const zeroitem2 = (Item, section) => {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

    $("#itemop").css("opacity", "1");
    $("#Headop").css("opacity", "1");
    $("#sliderOp").css("opacity", "1");
    $("#recordOp").css("opacity", "1");
    $("#formOp").css("opacity", "1");
    $("#footer").css("opacity", "1");
    $("#itemop").css("pointer-events", "visible");
    // Update state with incremented value
    let index = Section.findIndex(Section => Section.sectionID === section);
    let itemsec = Section[index].items
    let index2 = itemsec.findIndex(itemsec => itemsec.itemID === Item.itemID);

    setpopupprice(false)
    itemsec[index2].itemQty = 0
    orderdata(itemsec[index2]);
  }

  /////////use order for orderdata////
  const [decimal, setdecimal] = useState()

  const orderdata = (order, sectionid) => {
    ///define object of order and set the value 
    const orderlist = props.OrderItemLst

    // onject set value of oreder 
    const objorder = {
      'itemID': order.itemID,
      'itemQty': order.itemQty,
      'Qty': order.itemQty,
      'itemName': order.itemName,
      'itemDiscountedPrice': order.itemDiscountedPrice,
      'itemSalesPrice': order.itemSalesPrice,
      'Price': order.itemSalesPrice,

      'itemDiscountAmount': order.itemSalesPrice - order.itemDiscountedPrice,
      "total": order.itemDiscountedPrice * order.itemQty,
      "netprice": order.itemDiscountedPrice,
      'deliveryOrder': props.headerObj.defaultDeliveryPrice,
      'sectionID': order.sectionID,
      'eqvID': order.eqvID || eqvitemid,
      'eqvName': order.eqvName || eqvitem,
      // 'DeliveryEmployeeID':saleId,
      'defaultUnt': order.defaultUnt,
      'itemEqvUnts': order.itemEqvUnts,
      "isdecimal": decimal

    }
    //define total to use it in final of table 
    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;

    if (order.itemEqvUnts != null) {
      let indexofitem = order.itemEqvUnts.findIndex(eqv => eqv.eqvID === order.eqvID);
      if (indexofitem > -1 && order.itemEqvUnts[indexofitem].salePrice != null)
        order.itemSalesPrice = order.itemEqvUnts[indexofitem].salePrice
    }
    //condition of quantaity
    if (order.itemQty != 0) {
      //set the order in temporary array and slice orderlist 
      let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
      if (index !== -1) {
        let temporaryarray = orderlist.slice();
        //asign temporary arry of index to orderdata 
        temporaryarray[index]["itemQty"] = order.itemQty;
        temporaryarray[index]["Qty"] = order.itemQty;
        temporaryarray[index]["eqvName"] = order.eqvName;
        temporaryarray[index]["eqvID"] = order.eqvID;
        if (order.eqvID == undefined || order.eqvID == null) {
          temporaryarray[index]["netprice"] = order.itemDiscountedPrice * order.itemQty;
          temporaryarray[index]["total"] = order.itemQty * order.itemDiscountedPrice;
          temporaryarray[index]["itemSalesPrice"] = order.itemSalesPrice;
          temporaryarray[index]["itemDiscountedPrice"] = order.itemDiscountedPrice;




        }
        else {
          let indexofitem = order.itemEqvUnts.findIndex(eqv => eqv.eqvID === order.eqvID);

          temporaryarray[index]["netprice"] = order.itemEqvUnts[indexofitem].salePrice * order.itemQty;
          temporaryarray[index]["total"] = order.itemEqvUnts[indexofitem].salePrice * order.itemQty;
          temporaryarray[index]["itemSalesPrice"] = order.itemEqvUnts[indexofitem].salePrice;
          temporaryarray[index]["itemDiscountedPrice"] = order.itemEqvUnts[indexofitem].salePrice;



        }

        temporaryarray[index]["sectionID"] = order.sectionID;

        //loop for temporary array to show the data 
        temporaryarray.forEach((ele) => {
          totalqty = totalqty + ele.itemQty;
          totalpricedel = totalpricedel + ele.itemDiscountedPrice;
          totaldiscount = totaldiscount + (ele.itemDiscountAmount * (ele.itemQty));
          totalprice = totalprice + ele.price;
          Nettotal = Nettotal + ele.total;
        })
        // if quantaity !=-1 
      } else {
        objorder.itemDiscountedPrice = objorder.itemSalesPrice
        objorder.netprice = objorder.itemQty * objorder.itemDiscountedPrice
        objorder.total = objorder.itemQty * objorder.itemSalesPrice
        objorder.itemDiscountAmount = order.itemDiscountAmount
        objorder.itemQty = order.itemQty
        objorder.Qty = order.itemQty
        const NeworderLst = [...orderlist, objorder];
        props.SetOrderItemLst(NeworderLst)
        //set te data of neworderlist and loop to that 
        NeworderLst.forEach((ele) => {
          totalqty = totalqty + ele.itemQty;
          totalpricedel = totalpricedel + ele.itemDiscountedPrice;
          totaldiscount = totaldiscount + (Number(ele.itemDiscountAmount.toFixed(2)) * ele.itemQty);
          totalprice = totalprice + ele.itemSalesPrice;
          Nettotal = Nettotal + ele.total;
        })

      }
      //create object ototal and  set total to object total  
      const objtotal = {
        'totalqut': totalqty,
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount,
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      settotal(objtotal);
      localStorage.setItem('objtotal', JSON.stringify(objtotal))

    }
    else {
      let copy = [...orderlist]
      copy = copy.filter(
        (Item, id) => order.itemID != Item.itemID
      )
      props.SetOrderItemLst(copy)

      copy.forEach((ele) => {
        totalqty = totalqty + parseInt(ele.itemQty);
        totalpricedel = totalpricedel + ele.itemDiscountedPrice;
        totaldiscount = totaldiscount + ele.itemDiscountAmount;
        totalprice = totalprice + ele.price;
        Nettotal = Nettotal + ele.total;
      })
      const objtotal = {
        'totalqut': parseInt(totalqty),
        'totalpricedel': totalpricedel,
        'totaldiscount': totaldiscount * parseInt(totalqty),
        'totalprice': totalprice,
        'Nettotal': Nettotal
      }
      settotal(objtotal);
      localStorage.setItem('objtotal', JSON.stringify(objtotal))

    }
  }
  ////////section id ////
  const [Section, setSection] = useState([]);
  const [salesman, setsalesman] = useState([])
  useEffect(() => {
    setSection(props.headerObj.sections)
    setsalesman(props.headerObj.salesmen)
  }, [])
  // // Update state with incremented value
  const changequt = (Item) => {
    if (Item.itemQty == 0)
      ChangeQty(Item, '+');
    else
      ChangeQty(Item, '0');
  }






  const [popupprice, setpopupprice] = useState(false)
  const [priceinpopupprice, setpriceinpopupprice] = useState()
  const [Nameinpopupprice, setNameinpopupprice] = useState()
  const [showarray, setshowarray] = useState(false)
  const [iteminbtn, setiteminbtn] = useState()
  const [timer, setTimer] = useState(null);


  // const handleTouchStart = () => {
  //   setTimer();
  // };

  // const handleTouchEnd = () => {
  //   clearTimeout(timer);
  // };
  const [popupofaddone, setpopupofaddone] = useState(false)
  const [sectionid, setsectionid] = useState()
  const [eqvitem, seteqvitem] = useState()
  const [eqvitemid, seteqvitemid] = useState()

  //  const showpopupprice=(itemObj,price,sectionID,itemeqv,itemeqvId) =>{
  //   seteqvitem(itemeqv)
  //   seteqvitemid(itemeqvId)

  //   setsectionid(itemObj.sectionID)
  // setpopupprice(true)
  // setpopupofaddone(false)
  //   setpriceinpopupprice(price)
  //   setiteminbtn(itemObj)
  //   setdropdowneqv(false)
  // //   setNameinpopupprice(itemObj.itemName)

  //  }


  const showpopupprice = (order) => {


    if (order.defaultUnt == null) {
      seteqvitem(null)
      seteqvitemid(null)

    }
    else if (eqvitem == undefined) {
      seteqvitem(order.defaultUnt.eqvName)
      seteqvitemid(order.defaultUnt.eqvID)
    }
    else if (eqvitem != null) {
      seteqvitem(order.defaultUnt.eqvName)
      seteqvitemid("")
    }

    if (order.defaultUnt.isDecimal == true) {
      $("#popup").css("height", "535px");
    }
    else {
      $("#popup").css("height", "515px");
    }




    setsectionid(order.sectionID)
    setpopupprice(true)
    setpopupofaddone(false)
    setpriceinpopupprice(order.netprice)

    setiteminbtn(order)
    setdropdowneqv(false)
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
    // $(":button").attr('disabled', true);//button remains disabled
    $("#itemop").css("pointer-events", "none");
    $("#itemop").css("opacity", "0.5");
    $("#Headop").css("opacity", "0.5");
    $("#sliderOp").css("opacity", "0.5");
    $("#recordOp").css("opacity", "0.5");
    $("#formOp").css("opacity", "0.5");
    $("#footer").css("opacity", "0.5");
    //  setNameinpopupprice(itemObj.itemName)
  }


  function closepricepopup() {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
    $("#itemop").css("pointer-events", "visible");
    $("#itemop").css("opacity", "1");
    $("#Headop").css("opacity", "1");
    $("#sliderOp").css("opacity", "1");
    $("#recordOp").css("opacity", "1");
    $("#formOp").css("opacity", "1");
    $("#footer").css("opacity", "1");
    setpopupprice(false)
    setarray([])
    setshowarray(false)

  }


  const [array, setarray] = useState([])
  const [btnval, setbtnvalue] = useState()
  function setbtnval1(value) {

    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }
  }
  function setbtnval2(value) {

    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }
  }
  function setbtnval3(value) {

    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }
  }
  function setbtnval4(value) {

    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }
  }
  function setbtnval5(value) {

    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }
    // console.log(array.findIndex(isLargeNumber));
    // const myArrayS = array.split(".");
    // value
    // console.log("myArray",myArrayS)

  }
  function setbtnval6(value) {


    if (array.length > 3) {
      Swal.fire("للقطع لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }

  }
  function setbtnval7(value) {


    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }

  }
  function setbtnval8(value) {


    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      setarray(current => [...current, value]);
      setshowarray(true)
    }
  }
  function setbtnval9(value) {


    if (array.length > 3) {
      Swal.fire("لقد تعديت الحد الاقصي")
    }
    else {
      setbtnvalue(btnval + value)

      console.log(array)

      if (array.length == 0) {
        setarray(current => [...current, value]);
        setshowarray(true)

      }
      else {
        const indexofDel = array.indexOf(".00")
        if (indexofDel == -1) {
          setarray(current => [...current, value]);
          setshowarray(true)
        }
        else {
          array[indexofDel] = "." + value

          setarray(current => [...current]);
          setshowarray(true)
        }
        console.log(array.indexOf(".00"));
        // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
        //   console.log(previousSecondElementOfTheArray)


      }
    }
  }
  function setbtnval10(value) {
    // console.log("0", 0)
    // console.log(array)
    if (array.length == 0) {
      setarray([])
      Swal.fire({
        title: 'يجب ادخال كميه  ',
        icon: 'warning',

        confirmButtonText: 'تم'
      })
    }
    else {

      if (array.length > 3) {
        Swal.fire("لقد تعديت الحد الاقصي")
      }
      else {
        setbtnvalue(btnval + value)

        console.log(array)

        if (array.length == 0) {
          setarray(current => [...current, value]);
          setshowarray(true)

        }
        else {
          const indexofDel = array.indexOf(".00")
          if (indexofDel == -1) {
            setarray(current => [...current, value]);
            setshowarray(true)
          }
          else {
            array[indexofDel] = "." + value

            setarray(current => [...current]);
            setshowarray(true)
          }
          console.log(array.indexOf(".00"));
          // const previousSecondElementOfTheArray = array.splice(array.length, indexofDel);
          //   console.log(previousSecondElementOfTheArray)


        }
      }
    }


  }
  function backspacebtn() {
    console.log("array.len", array)
    array.pop()
    console.log("array.len2", array)
    setarray(current => [...current]);
    console.log("array.len3", array)




  }
  const ChangeQty2 = (Item, eqvid) => {
    // Update state with incremented value


    if (array.length == 0) {
      Item.eqvID = eqvid
      orderdata(Item);
      setpopupprice(false)
    }
    if (array.length == 1) {
      Item.eqvID = eqvid
      Item.itemQty = array[0].toString()

      if (Item.itemMaximumOrderQty == 0) {

        orderdata(Item)
      }
      else {
        if (parseInt(Item.itemQty) > Item.itemMaximumOrderQty) {
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.itemQty = Item.itemMaximumOrderQty
          orderdata(Item)
        }
        else {
          orderdata(Item);
        }
      }

    }
    else if (array.length == 2) {
      Item.eqvID = eqvid
      Item.itemQty = array[0].toString() + array[1].toString();
      if (Item.itemMaximumOrderQty == 0) {
        orderdata(Item)
      }
      else {
        if (parseInt(Item.itemQty) > Item.itemMaximumOrderQty) {
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.itemQty = Item.itemMaximumOrderQty
          orderdata(Item)
        }
        else {
          orderdata(Item);
        }
      }

    }
    else if (array.length == 3) {
      Item.eqvID = eqvid
      Item.itemQty = array[0].toString() + array[1].toString() + array[2].toString();

      if (parseInt(Item.itemQty) > Item.itemMaximumOrderQty) {
        orderdata(Item)
      }

      else {
        if (parseInt(Item.itemQty) > Item.itemMaximumOrderQty) {
          Swal.fire("لقد تعديت الحد الاقصي")
          Item.itemQty = Item.itemMaximumOrderQty
          orderdata(Item)
        }
        else {
          orderdata(Item);
        }
      }

    }
    else if (array.length == 4) {
      Item.eqvID = eqvid
      Item.itemQty = array[0].toString() + array[1].toString() + array[2].toString() + array[3].toString();
      if (parseInt(Item.itemQty) > Item.itemMaximumOrderQty) {
        Swal.fire("لقد تعديت الحد الاقصي")
        Item.itemQty = Item.itemMaximumOrderQty
        orderdata(Item)
      }
      else {
        orderdata(Item);
      }

    }
    else if (array.length > 4) {
      if (Item.itemQty > Item.itemMaximumOrderQty && Item.itemMaximumOrderQty > 0) {
        Swal.fire("لقد تعديت الحد الاقصي")
      }
      else {
        orderdata(Item);

      }
    }
    if (parseInt(Item.qty) > Item.itemMaximumOrderQty) {
      Swal.fire("لقد تعديت الحد الاقصي")
      Item.qty = Item.itemMaximumOrderQty
      orderdata(Item)
    }

  }

  function showarrayleng(item, netprice, itemeqvid) {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;

    $("#itemop").css("pointer-events", "visible");

    $("#itemop").css("opacity", "1");
    $("#Headop").css("opacity", "1");
    $("#sliderOp").css("opacity", "1");
    $("#recordOp").css("opacity", "1");
    $("#formOp").css("opacity", "1");
    $("#footer").css("opacity", "1");
    setiteminbtn(item);

    if (item.eqvID == null || item.eqvID == undefined) {
      seteqvitem(null)
      seteqvid(null)
      seteqvname(null)
    }
    else {
      seteqvitem(item.eqvName)
      seteqvid(item.eqvID)
      seteqvname(item.eqvName)
    }
    var index = -1;
    for (var i = 0; i < Section.length; i++) {
      if (Section[i].sectionID === item.sectionID) {
        index = i;
        break;
      }
    }

    let itemsec = [];
    if (index !== -1) {
      itemsec = Section[index].items;
    }

    var index2 = -1;
    for (var j = 0; j < itemsec.length; j++) {
      if (itemsec[j].itemID === item.itemID) {
        index2 = j;
        break;
      }
    }


    ChangeQty2(itemsec[index2], item.eqvID);
    setpopupprice(false);
    setarray([]);
    setshowarray(false);
  }

  function showitemsec(section, id) {

    var index = section.findIndex(section => section.sectionID === id);
    // setidofitem(id)

    const newList = section.map((item) => {
      if (item.sectionID === id) {
        const updatedItem = {
          ...item,
          showSection: !item.showSection,
        };

        return updatedItem;
      }

      return item;
    });

    setSection(newList);
  }

  const [eqvid, seteqvid] = useState()
  const [eqvname, seteqvname] = useState()
  const seteqv = (item, EqvId, EqvName) => {
    // let index1 = item.findIndex(item => item.Id === id);
    seteqvitem(EqvName)
    seteqvid(EqvId)
    seteqvname(EqvName)
    item.eqvID = EqvId
    item.eqvName = EqvName
    let itemE = item.itemEqvUnts
    let index2 = itemE.findIndex(itemeqv => itemeqv.eqvID === EqvId);
    item.itemDiscountedPrice = item.itemEqvUnts[index2].salePrice

    item.itemSalesPrice = item.itemEqvUnts[index2].salePrice
    setdecimal(item.itemEqvUnts[index2].isDecimal)
    item.isdecimal = item.itemEqvUnts[index2].isDecimal
    orderdata(item);
    //   item.itemEqvUnts[index2].
  }
  const [dropdowneqv, setdropdowneqv] = useState(false)
  const [iddrodwn, setiddrodwn] = useState()
  function showdropdown(id) {
    if (dropdowneqv == false) {
      setdropdowneqv(true)
      setiddrodwn(id)

    }
    else {
      setdropdowneqv(false)
    }
  }
  // const[popupsaleman,setpopupsaleman]=useState(false)
  // function showsalemanpopup(){
  //   props.setpopupsaleman(true)
  // }
  // function hidealemanpopup(){
  //   props.setpopupsaleman(false)
  // }
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const [loading, setLoading] = useState(false);

  const createScreenshotPDF = () => {
    const MARGIN = 2;
    // get the content element

    document.getElementById('orderprint2').style.display = "block"
    const content = document.getElementById('orderprint2');



    // get content size
    const width = 397;
    const height = content.clientHeight;

    // call html2canvas to save the screenshot to a pdf file
    html2canvas(content, {
      scrollX: 0, // scroll to X origin
      scrollY: 5, // scroll to Y origin
      scale: 5, // 5 is quality scale
    }).then(function (canvas) {
      // convert the canvas conent to a Data URI/URL
      var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px', // set pixel as unit
        format: [width + MARGIN * 1, height + MARGIN * 1],
      });

      // add canvas as an image
      doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

      // save the content to screenshot.pdf
      doc.save('الطلبيه.pdf');
      content.style.display = "none"


    });
  };

  const createScreenshotPDFMob = () => {
    const MARGIN = 2;
    setLoading(true)

    // get the content element
    document.getElementById('orderprint2').style.display = "none"

    document.getElementById('orderprint11').style.display = "block"
    const content = document.getElementById('orderprint11');



    // get content size
    const width = content.offsetWidth;
    const height = content.clientHeight;

    // call html2canvas to save the screenshot to a pdf file
    html2canvas(content, {
      scrollX: 0, // scroll to X origin
      scrollY: 5, // scroll to Y origin
      scale: 5, // 5 is quality scale
    }).then(function (canvas) {
      // convert the canvas conent to a Data URI/URL
      var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
      var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px', // set pixel as unit
        format: [width + MARGIN * 1, height + MARGIN * 1],
      });

      // add canvas as an image
      doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

      // save the content to screenshot.pdf
      doc.save('الطلبيه.pdf');


    });
    document.getElementById('orderprint11').style.display = "none"
    setTimeout(() => {
      setLoading(false)

    }, 50);

  };
  function setsale(name, id) {
    props.setsaleIdP(name)
    props.setsaleIdmanP(id)


    props.setpopupsaleman(false)
  }
  function fDecimalNumber(value) {
    const str = value.toString()
    // const str = value.replace(/^0+/, '');
    const myArray = str.split("0");
    // value
    console.log("myArray", myArray)
    ///////////////
    console.log("myArray", array)
    console.log("sssss", (array.toString().includes(".")))
    if (array.length == 1) {
      let index = array.findIndex(array => array === myArray[1]);

      if (index === -1) {

        value = (parseFloat(myArray[1]))
        myArray[1] = value + parseFloat(array[0])
        array[0] = 0 + myArray[1]



        setarray(current => [...array]);
        localStorage.setItem("btnval", myArray[1])

      }
      else {
        array.splice(index);

        value = parseFloat(myArray[1]) + parseFloat(value)
        const str1 = value.toString()

        const array2 = str1.split("0")
        // array.splice(index,value);
        setarray(current => [...array, value]);
        localStorage.setItem("btnval", myArray[1])


      }
    }
    else {
      let index = array.findIndex(array => array === localStorage.getItem("btnval"));

      if (index === -1) {
        if (array.toString().includes(".") == true) {
          array.indexOf(".");
          const myArray = str.split("0");
          value = parseFloat(myArray[1]) + parseFloat(value)

          let sum = "";

          array.forEach(item => {
            sum += item;
          });

          console.log(sum);

          let thevalue = (parseFloat(myArray[1]) + parseFloat(sum)).toString();
          setarray(current => [thevalue]);
        }
        else {
          value = parseFloat(myArray[1])
          console.log(array.length)
          if (array.length == 0) {
            let zero = 0;
            myArray[1] = zero.toString() + myArray[1]


            setarray(current => [...array, myArray[1]]);
          }
          else {
            let zero = "";
            myArray[1] = zero.toString() + myArray[1]


            setarray(current => [...array, myArray[1]]);
          }

        }



      }
      else {
        array.splice(index);

        value = parseFloat(localStorage.getItem("btnval")) + parseFloat(value)
        const str1 = value.toString()

        const array2 = str1.split("0")
        // array.splice(index,value);
        setarray(current => [...array, array2[1]]);

      }
    }
  }
  function fDecimalNumberpoint(value) {

    const str = value.toString()
    // const str = value.replace(/^0+/, '');
    const myArray = str.split("*");
    // value
    console.log("myArray", myArray)
    ///////////////
    console.log("myArray", array)
    console.log(array.includes("."))
    if (array.length == 1) {
      if ((array[0].toString()).includes(".")) {
        let index = array.findIndex(array => array === myArray[1]);

        if (index === -1) {

          value = parseFloat(myArray[1])




          setarray(current => [...array]);
          localStorage.setItem("btnval", myArray[1])

        }
        else {
          array.splice(index);

          value = parseFloat(myArray[1]) + parseFloat(value)
          const str1 = value.toString()

          const array2 = str1.split("0")
          // array.splice(index,value);
          setarray(current => [...array, array2[1]]);
          localStorage.setItem("btnval", myArray[1])


        }
      }
      else {
        let index = array.findIndex(array => array === myArray[1]);

        if (index === -1) {

          value = parseFloat(myArray[1])




          setarray(current => [...array, myArray[1]]);
          localStorage.setItem("btnval", myArray[1])

        }
        else {
          array.splice(index);

          value = parseFloat(myArray[1]) + parseFloat(value)
          const str1 = value.toString()

          const array2 = str1.split("0")
          // array.splice(index,value);
          setarray(current => [...array, array2[1]]);
          localStorage.setItem("btnval", myArray[1])


        }
      }

    }
    else {
      let index = array.findIndex(array => array === localStorage.getItem("btnval"));

      if (index === -1) {

        value = parseFloat(0 + myArray[1])




        setarray(current => [...array, myArray[1]]);


      }
      else {
        array.splice(index);

        value = parseFloat(localStorage.getItem("btnval")) + parseFloat(value)
        const str1 = value.toString()

        const array2 = str1.split("0")
        // array.splice(index,value);
        setarray(current => [...array, array2[1]]);

      }
    }

  }

  const handleClick = (itemObj) => {
    console.log(itemObj);
    if (itemObj.itemRecipes == null || itemObj.itemRecipes.length == 0) {
      showpopupprice(itemObj)
    } else {
      $("#itemop").css("pointer-events", "none");
      $("#itemop").css("opacity", "0.5");
      $("#Headop").css("opacity", "0.5");
      $("#sliderOp").css("opacity", "0.5");
      $("#recordOp").css("opacity", "0.5");
      $("#formOp").css("opacity", "0.5");
      $("#footer").css("opacity", "0.5");
      setpopupofaddone(true);
      setitemObj(itemObj);
    }
  };


  const AcceptData = () => {
    $("#itemop").css("pointer-events", "visible");
    $("#itemop").css("opacity", "1");
    $("#Headop").css("opacity", "1");
    $("#sliderOp").css("opacity", "1");
    $("#recordOp").css("opacity", "1");
    $("#formOp").css("opacity", "1");
    $("#footer").css("opacity", "1");
    setpopupofaddone(false);
    let SecItem = Section.find(sectio => sectio.sectionID === itemObj.sectionID);
    if (SecItem != null) {
      var item = SecItem.items.find(it => it.itemID === itemObj.itemID);
      item.itemQty = itemObj.itemQty;
      item.itemRecipes = itemObj.itemRecipes;
    }


    ///define object of order and set the value 
    const orderlist = props.OrderItemLst
    //define total to use it in final of table 
    let totalpricedel = 0;
    let totalqty = 0;
    let totalprice = 0;
    let totaldiscount = 0;
    let Nettotal = 0;
    let NeworderLst = [...orderlist];
    itemObj.itemRecipes.map((order, index) => {
      // onject set value of oreder 
      const objorder = {
        'itemID': order.itemID,
        'itemQty': order.itemQty,
        'Qty': order.itemQty,
        'itemName': order.itemName,
        'itemDiscountedPrice': order.itemDiscountedPrice,
        'itemSalesPrice': order.itemSalesPrice,
        'Price': order.itemSalesPrice,

        'itemDiscountAmount': order.itemSalesPrice - order.itemDiscountedPrice,
        "total": order.itemDiscountedPrice * order.itemQty,
        "netprice": order.itemDiscountedPrice,
        'deliveryOrder': props.headerObj.defaultDeliveryPrice,
        'sectionID': order.sectionID,
        'eqvID': order.eqvID || eqvitemid,
        'eqvName': order.eqvName || eqvitem,
        // 'DeliveryEmployeeID':saleId,
        'defaultUnt': order.defaultUnt,
        'itemEqvUnts': order.itemEqvUnts,
        "isdecimal": decimal,
        "itemRecipes": itemObj.itemRecipes,
        "ParentId": itemObj.itemID
      }
      if (order.itemQty != 0) {
        let index = orderlist.findIndex(orderlist => orderlist.itemID === order.itemID);
        if (index !== -1) {
          let temporaryarray = orderlist.slice();
          temporaryarray[index]["itemQty"] = order.itemQty;
          temporaryarray[index]["Qty"] = order.itemQty;
          temporaryarray[index]["eqvName"] = order.eqvName;
          temporaryarray[index]["eqvID"] = order.eqvID;
          temporaryarray[index]["netprice"] = order.itemDiscountedPrice * order.itemQty;
          temporaryarray[index]["total"] = order.itemQty * order.itemDiscountedPrice;
          temporaryarray[index]["itemSalesPrice"] = order.itemSalesPrice;
          temporaryarray[index]["itemDiscountedPrice"] = order.itemDiscountedPrice;
          temporaryarray[index]["sectionID"] = order.sectionID;

        } else {
          objorder.itemDiscountedPrice = objorder.itemSalesPrice
          objorder.netprice = objorder.itemQty * objorder.itemDiscountedPrice
          objorder.total = objorder.itemQty * objorder.itemSalesPrice
          objorder.itemDiscountAmount = order.itemDiscountAmount
          objorder.itemQty = order.itemQty
          objorder.Qty = order.itemQty
          NeworderLst = [...NeworderLst, objorder];
        }
      }
      else {
        NeworderLst = NeworderLst.filter(
          (Item, id) => order.itemID != Item.itemID || Item.ParentId == null || itemObj.itemID != Item.ParentId
        )
      }

    })
    NeworderLst.forEach((ele) => {
      totalqty = totalqty + parseInt(ele.itemQty);
      totalpricedel = totalpricedel + ele.itemDiscountedPrice;
      totaldiscount = totaldiscount + ele.itemDiscountAmount;
      totalprice = totalprice + ele.price;
      Nettotal = Nettotal + ele.total;
    })
    props.SetOrderItemLst(NeworderLst)
    const objtotal = {
      'totalqut': totalqty,
      'totalpricedel': totalpricedel,
      'totaldiscount': totaldiscount,
      'totalprice': totalprice,
      'Nettotal': Nettotal
    }
    settotal(objtotal);
    localStorage.setItem('objtotal', JSON.stringify(objtotal))
  };

  const closepopupofaddone = () => {
    $("#itemop").css("pointer-events", "visible");
    $("#itemop").css("opacity", "1");
    $("#Headop").css("opacity", "1");
    $("#sliderOp").css("opacity", "1");
    $("#recordOp").css("opacity", "1");
    $("#formOp").css("opacity", "1");
    $("#footer").css("opacity", "1");
    setpopupofaddone(false);
  }

  return (

    <div>


      <div className='container-fluid' >

        <div className='col-12' id='itemop'>
          {Section && Section.length > 0 && Section.map((SectionObj, index) => (
            <div>
              <div >

                {/* onClick={() => showitemsec(SectionObj.sectionID)} */}

                {/* backgroundImage:`url(${require("../image/tijarylogo.png")})` */}
                <div className='col-12 mt-2' onClick={() => showitemsec(Section, SectionObj.sectionID)} style={{ backgroundImage: `url(${encodeURI(SectionObj.sectionBanner)})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', maxHeight: "130px", height: "130px" }}>
                  {SectionObj.showSection == true ?


                    <div className='row'>
                      {/* background:"#d8dcdf78", */}
                      <div className='col-2 flagofimg' style={{ height: "22px", }} onClick={() => showitemsec(Section, SectionObj.sectionID)}>

                        <p>  <IoIosArrowUp style={{ fontSize: "28px", fontWeight: "bold", color: "white", marginLeft: "-18px" }}></IoIosArrowUp></p>

                      </div>
                      <div className='col-12 flagofimg3'>
                        <p className='fontinpopupp fontmaaray text-white' >{SectionObj.sectionName}</p>
                      </div>
                    </div>
                    :

                    <div className='row'>
                      {/* background:"#d8dcdf78", */}
                      <div className='col-2 flagofimg2' onClick={() => showitemsec(Section, SectionObj.sectionID)}>

                        <p>  <IoIosArrowDown style={{ fontSize: "28px", fontWeight: "bold", color: "white", marginLeft: "-18px" }}></IoIosArrowDown></p>


                      </div>
                      <div className='col-12 flagofimg3'>
                        <p className='fontinpopupp fontmaaray text-white '>{SectionObj.sectionName}</p>
                      </div>
                      {/* <div className='col-2'>
<p>{SectionObj.sectionName}</p>
</div> */}
                    </div>
                  }
                  {/* <img  className='ban ' src={} style={{"max-height":"303px"}}></img> */}
                </div>
                {/* <div style={{backgroundImage:`url(${SectionObj.sectionBanner})`,maxHeight:"250px" ,width:"100%",height:"100px"}}></div> */}
                {SectionObj.showSection != true ?
                  "" : <div className='Items mt-3 '>
                    {Section && Section.length > 0 && SectionObj.items.map((itemObj, index) => (

                      <>
                        <div class=" col-12 col-lg-12 col-md-4  ItemCard  itemindesktop">{


                          <div class="shadow bg-white  rounded d-flex flex-column card-body " >

                            {/*design to card */}

                            {/* */}

                            <div className='card-body' style={{ background: "#faf8f8" }}>
                              <div className='container-fluid'>
                                <div className='row'>
                                  <div className='col-2'>
                                    {itemObj.itemRecipes && itemObj.itemRecipes.length > 0 ?
                                      <p style={{ textAlign: "center", direction: "ltr", fontSize: "32px", marginTop: "9%" }}>{(itemObj.itemRecipes.reduce((acc, val) => acc + (val.itemQty * val.itemDiscountedPrice), 0)).toLocaleString()}</p>

                                      : itemObj.eqvID == undefined || itemObj.eqvID == null ?
                                        <p style={{ textAlign: "center", direction: "ltr", fontSize: "32px", marginTop: "9%" }}>{((itemObj.itemDiscountedPrice) * (itemObj.itemQty)).toLocaleString()}</p>


                                        : <p style={{ textAlign: "center", direction: "ltr", fontSize: "32px", marginTop: "9%" }}>{((itemObj.itemSalesPrice) * (itemObj.itemQty)).toLocaleString()}</p>}

                                  </div>

                                  <div className='col-2 itemcardhei p-0'
                                  >



                                    <div className='col-12 brnplus p-0'>

                                      {itemObj.itemRecipes && itemObj.itemRecipes.length == 0 ?

                                        <div className='' style={{ background: props.headerObj.buttonColor, marginTop: "9%", marginLeft: "5%", width: "90%", display: "flex", height: "45px", borderRadius: "5px" }} >
                                          <div className='col-4' onClick={() => { ChangeQty(itemObj, "-") }}>
                                            <AiOutlineMinus style={{ fontSize: "30px", color: "white", marginTop: "15%" }} />
                                          </div>
                                          <div className='col-4'
                                            onClick={() => { handleClick(itemObj) }} >
                                            <p style={{ fontSize: "32px", color: "white" }}>{itemObj.itemQty}</p>

                                          </div>

                                          <div className='col-4 p-0' style={{ color: "white", fontSize: "30px" }} onClick={() => { ChangeQty(itemObj, "+") }} >
                                            <AiOutlinePlus />
                                          </div>
                                        </div>
                                        :
                                        <div className='' style={{
                                          background: 'DarkOrange'
                                          , marginTop: "9%", marginLeft: "5%", width: "90%", display: "flex", height: "45px", borderRadius: "5px"
                                        }} >

                                          <div className='col-12' onClick={() => { handleClick(itemObj) }} >
                                            <p style={{ fontSize: "32px", color: "white" }}>{itemObj.itemQty}</p>

                                          </div>

                                        </div>
                                      }



                                    </div>
                                  </div>
                                  <div className='col-2'>
                                    {itemObj.itemRecipes && itemObj.itemRecipes.length > 0 ?
                                      <p style={{ textAlign: "center", direction: "ltr", fontSize: "32px", marginTop: "9%" }}>{itemObj.itemRecipes[0].itemDiscountedPrice.toLocaleString()}</p>
                                      : itemObj.eqvID == undefined || itemObj.eqvID == null ?
                                        <p style={{ textAlign: "center", direction: "ltr", fontSize: "32px", marginTop: "9%" }}>{itemObj.itemDiscountedPrice.toLocaleString()}</p>

                                        : <p style={{ textAlign: "center", direction: "ltr", fontSize: "32px", marginTop: "9%" }}>{itemObj.itemSalesPrice.toLocaleString()}</p>}
                                  </div>

                                  {/* onClick={()=>{showpopupprice(itemObj)}} */}
                                  <div className='col-6 itemcardhei' style={{ borderLeft: "1px solid lightgray" }} onClick={() => { handleClick(itemObj) }}>
                                    <div className='row' style={{ justifyContent: "left" }}>
                                      <button style={{ width: "23%", 'height': '60%', 'border': '0', 'z-index': '100', position: "absolute" }} className='btn'>
                                        {itemObj.itemQty > 0 && <AiOutlineCheck className='fs-1' style={{ 'color': 'red', 'width': '30px', 'height': '30px', 'margin-top': '-31px', marginRight: "16px" }}></AiOutlineCheck>}  </button>
                                    </div>
                                    <div className='row'>
                                      <div className='col-12' >
                                        <h5 className='card-title ItemName fontmaaray' id={itemObj.itemID} style={{ direction: "rtl", cursor: "pointer", 'color': props.headerObj.fontcolor, fontSize: "32px", lineHeight: "35px", marginRight: "3%", marginTop: "0" }} >{itemObj.itemDisplayName}</h5>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        </div>


                        <div class=" col-12 col-lg-12 col-md-4  ItemCard iteminmobile ">{
                          <div class="shadow bg-white itemindesktop rounded d-flex flex-column card-body " >

                            {/*design to card */}

                            {/* */}

                            <div className='card-body' style={{ background: "#faf8f8" }}>
                              <div className='container-fluid'>
                                <div className='row'>
                                  <div className='col-3 itemcardhei p-0'
                                    onClick={() => { handleClick(itemObj) }}
                                  >
                                    <div className='col-12' style={{ height: "36px" }} >
                                      {itemObj.itemRecipes && itemObj.itemRecipes.length > 0 ?
                                        <div className='col-12' style={{ height: "60px" }} onClick={() => handleClick(iteminbtn)}>
                                          <div className='d-flex'>

                                            <div className='col-12 col-lg-9'>
                                              <div className='row'>
                                                <p style={{ marginBottom: "0px", fontSize: "14px" }}>سعر</p>
                                              </div>
                                              <div className='row'>
                                                {itemObj.eqvID == undefined || itemObj.eqvID == null ?
                                                  <p style={{ textAlign: "center", direction: "ltr", marginTop: "-8%", fontSize: "22px" }}>{(itemObj.itemDiscountedPrice.toLocaleString())}</p>


                                                  : <p style={{ textAlign: "center", direction: "ltr", marginTop: "-8%", fontSize: "22px" }}>{(itemObj.itemSalesPrice.toLocaleString())}</p>
                                                }
                                                {/* <p style={{textAlign:"center",direction:"ltr",marginTop:"-8%",fontSize:"32px"}}>{itemObj.itemDiscountedPrice.toLocaleString()}</p> */}

                                              </div>
                                            </div>



                                          </div>
                                        </div>
                                        : <div className='col-12' onClick={() => handleClick(iteminbtn)}>
                                          <div className='d-flex'>

                                            <div className='col-12 col-lg-9'>
                                              <div className='row'>
                                                <p style={{ marginBottom: "0px", fontSize: "14px" }}>سعر</p>
                                              </div>
                                              <div className='row'>
                                                {itemObj.eqvID == undefined || itemObj.eqvID == null ?
                                                  <p style={{ textAlign: "center", direction: "ltr", marginTop: "-8%", fontSize: "22px" }}>{(itemObj.itemDiscountedPrice.toLocaleString())}</p>


                                                  : <p style={{ textAlign: "center", direction: "ltr", marginTop: "-8%", fontSize: "22px" }}>{(itemObj.itemSalesPrice.toLocaleString())}</p>
                                                }
                                                {/* <p style={{textAlign:"center",direction:"ltr",marginTop:"-8%",fontSize:"32px"}}>{itemObj.itemDiscountedPrice.toLocaleString()}</p> */}

                                              </div>
                                            </div>



                                          </div>
                                        </div>}


                                      {itemObj.itemRecipes && itemObj.itemRecipes.length == 0 ?

                                        <div className='col-12 brnplus p-0' style={{ marginTop: "-18px", height: "36px" }}

                                        //  onClick={()=> 
                                        //  showpopupprice(itemObj,itemObj.itemSalesPrice,itemObj.sectionID,itemObj.itemEqvUnts[0].eqvName,itemObj.itemEqvUnts[0].eqvID)}
                                        >
                                          {/*  */}
                                          <div className='' style={{ background: props.headerObj.buttonColor, marginLeft: "5%", width: "90%", display: "flex", height: "39px", borderRadius: "5px" }} onClick={() => { handleClick(itemObj) }}>
                                            <div className='col-4 p-0' style={{ color: "white", fontSize: "20px" }} >
                                              <AiOutlinePlus /><br></br><AiOutlineMinus style={{ marginTop: "-31px" }} />
                                            </div>
                                            <div className='col-8  p-0'>

                                              <p style={{ marginTop: "11%", fontSize: "20px", color: "white" }}>{itemObj.itemQty}</p>
                                            </div>

                                          </div>
                                        </div>
                                        : <div className='col-12 brnplus p-0' style={{ marginTop: "-18px", height: "36px" }}
                                        >
                                          <div className='' style={{ background: 'DarkOrange', marginLeft: "5%", width: "90%", display: "flex", height: "39px", borderRadius: "5px" }} onClick={() => { handleClick(itemObj) }}>


                                            <p className='col-12' style={{ fontSize: "20px", color: "white" }}>{itemObj.itemQty}</p>

                                          </div>
                                        </div>}

                                    </div>

                                  </div>

                                  <div className='col-9 itemcardhei' style={{ borderLeft: "1px solid lightgray" }}>
                                    <div className='row' style={{ justifyContent: "left" }}>


                                      <button style={{ width: "23%", 'height': '60%', 'border': '0', 'z-index': '100', position: "absolute" }} className='btn'
                                        onClick={() => { handleClick(itemObj) }}
                                      // onClick={()=> showpopupprice(iteminbtn,iteminbtn.itemSalesPrice,iteminbtn.sectionID,itemObj.itemEqvUnts[0].eqvName,itemObj.itemEqvUnts[0].eqvID)}  id='inputcheckqut'
                                      >
                                        {itemObj.itemQty > 0 && <AiOutlineCheck className='fs-1' style={{ 'color': 'red', 'width': '30px', 'height': '30px', 'margin-top': '-31px', marginRight: "16px" }}></AiOutlineCheck>}  </button>

                                    </div>
                                    <div className='row' style={{ marginTop: "3%" }} >
                                      {/* onClick={()=> showaddone(itemObj,itemObj.itemSalesPrice)} */}
                                      <h5 className='card-title ItemName fontmaaray' onClick={() => { handleClick(itemObj) }} id={itemObj.itemID} style={{ cursor: "pointer", 'color': props.headerObj.fontcolor, fontSize: "17px", lineHeight: "27px", direction: "rtl" }} >{itemObj.itemDisplayName}</h5>
                                      <h5 className='card-title fontmaaray' id='cardname' onClick={() => changequt(itemObj)} >{itemObj.average}</h5>
                                      <div className='col-11'>
                                        <div className='row' style={{ justifyContent: "right" }}>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        }

                        </div>
                      </>


                    ))}


                  </div>}

              </div>
            </div>
          ))}
        </div>

        {popupofaddone == true ?
          <ItemRecipePopUp itemObj={itemObj} setitemObj={setitemObj} setpopupofaddone={setpopupofaddone}
            AcceptData={AcceptData} closepopupofaddone={closepopupofaddone} />
          : ""}
        {popupprice == true ?
          <div className='col-lg-3 col-md-6 col-11 card popup' id='popup' >
            <div className='container-fluid'>
              <div className='row card-header p-0' style={{ height: "50px" }}>
                <div className='col-12'>

                  <div className='row'>
                    <div className='col-12'>

                      {iteminbtn.itemDiscountAmount == 0 ?
                        <div className='row' id={iteminbtn.itemID}>
                          <div className='col-6 p-0'>
                            <div className='row'>
                              {eqvitem != null ?
                                <div className='col-7'>
                                  <div className='col-12' onClick={() => showdropdown(iteminbtn.itemID)}
                                    style={{ background: "white", borderRadius: "5px", height: "27px", marginTop: "13%", marginLeft: "11%" }}>

                                    <div className='row'>
                                      <div className='col-1'>
                                        <IoIosArrowDown></IoIosArrowDown>
                                      </div>
                                      <div className='col-8 p-0 '>
                                        {iteminbtn.eqvName == undefined ?
                                          <p style={{ textAlign: "right", marginRight: "8%" }}>   {eqvitem}</p>
                                          : <p style={{ textAlign: "right", marginRight: "8%", direction: "rtl" }}>   {iteminbtn.eqvName}</p>
                                        }



                                      </div>

                                    </div>
                                    {dropdowneqv == true && iteminbtn.itemID == iddrodwn ?
                                      <div className='card dropdowneqv' >

                                        {iteminbtn.itemEqvUnts.map((itemObjeqv) => (
                                          <p id='eledropdown' style={{ borderBottom: "1px solid lightgrey", margin: "0px", cursor: "pointer" }} onClick={() => seteqv(iteminbtn, itemObjeqv.eqvID, itemObjeqv.eqvName)}
                                          >{itemObjeqv.eqvName}</p>))}


                                      </div>


                                      : ""
                                    }

                                  </div>
                                </div> : ""}

                              <div className='col-5' style={{ padding: "0px" }}>

                                <p className='fontmaaray' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{iteminbtn.itemQty} </p>


                              </div>
                            </div>

                          </div>
                          <div className='col-6 p-0'>
                            {console.log("iteminbtn", iteminbtn)}
                            {iteminbtn.eqvID == undefined ?
                              <p className='fontmaaray' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{iteminbtn.itemDiscountedPrice} جم</p>

                              : <p className='fontmaaray' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{iteminbtn.itemSalesPrice
                              } جم</p>
                            }
                            {/* .toLocaleString() */}

                          </div>




                        </div> :




                        <div className='row'>
                          <div className='col-4 p-0'>
                            <p className='fontmaaray' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "rtl" }}>{iteminbtn.itemQty} &nbsp;  {eqvitem} </p>


                          </div>
                          <div className='col-4 p-0'>
                            <p className='fontmaaray' style={{ direction: 'rtl', fontSize: "22px", fontWeight: "bold", marginTop: "7px", textAlign: "right", marginRight: "12px" }}>{iteminbtn.itemDiscountedPrice} جم</p>

                          </div>

                          <div className='col-4 p-0'>
                            <del className='fontmaaray' style={{ color: "#a70404", position: "absolute", direction: 'rtl', fontSize: "14px", fontWeight: "bold", marginTop: "7px", textAlign: "right", right: "7px" }}>{iteminbtn.pricedel
                            } جم</del>

                          </div>


                        </div>}

                    </div>


                  </div>




                </div>

              </div>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-12' style={{ fontSize: "20px" }}>
                    <p className='fontmaaray' style={{ textAlign: "center", marginTop: "17px", color: "green", fontWeight: "bold", direction: "rtl" }}>  {iteminbtn.itemDisplayName} </p>

                  </div>
                  <div className='row'>
                    <p className='fontmaaray' style={{ fontSize: " 22px", marginTop: "7px", fontWeight: "bold", direction: "ltr" }}> {array}  </p></div>
                </div>
                {console.log("iteminbtnssss", iteminbtn)}
                {iteminbtn.isdecimal == true ?
                  <div className='d-flex' style={{ height: "50px" }}>
                    <div className='col-2' style={{ marginRight: "4%", fontSize: "13px" }} onClick={() => fDecimalNumberpoint("*.00")}>
                      <button className='btn btn-primary w-100 h-100'>
                        <RxDotFilled style={{ fontSize: "26px" }}></RxDotFilled>
                      </button>
                    </div>


                    <div className='col-2' style={{ marginRight: "4%" }}>
                      <button className='btn btn-primary w-100 h-100' style={{ fontSize: "13px" }} onClick={() => fDecimalNumber(FOnumDecimal)}>1/2</button>

                    </div>

                    <div className='col-2' style={{ marginRight: "4%" }}>
                      <button className='btn btn-primary w-100 h-100' style={{ fontSize: "13px" }} onClick={() => fDecimalNumber(THnumDecimal)} >1/3</button>

                    </div>
                    <div className='col-2' style={{ marginRight: "4%" }} >
                      <button className='btn btn-primary w-100 h-100' style={{ fontSize: "13px" }} onClick={() => fDecimalNumber(SnumDecimal)} >1/4</button>

                    </div>
                    <div className='col-2' >
                      <button className='btn btn-primary h-100 w-100' style={{ fontSize: "13px" }} onClick={() => fDecimalNumber(FnumDecimal)} >1/8</button>

                    </div>
                  </div> : ""}

                <div className='row mt-3' >
                  <div className='col-4 p-0' >

                    <button className=' btn btn-secondary fontmaaray' id='btn1' value={btnval} onClick={() => setbtnval1(1)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>1</button>
                  </div>
                  <div className='col-4 p-0'  >

                    <button className='btn btn-secondary fontmaaray' id='btn2' value={btnval} onClick={() => setbtnval2(2)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>2</button>

                  </div>

                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray' id='btn3' onClick={() => setbtnval3(3)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>3</button>

                  </div>

                </div>
                <div className='row mt-2' >
                  <div className='col-4 p-0'  >

                    <button className='btn btn-secondary fontmaaray' id='btn4' onClick={() => setbtnval4(4)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>4</button>

                  </div>
                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray ' id='btn5' onClick={() => setbtnval5(5)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>5</button>

                  </div>
                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray' id='btn6' onClick={() => setbtnval6(6)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>6</button>

                  </div>


                </div>
                <div className='row mt-2' >
                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray' id='btn7' onClick={() => setbtnval7(7)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>7</button>

                  </div>
                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray' id='btn8' onClick={() => setbtnval8(8)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>8</button>

                  </div>
                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray' id='btn9' onClick={() => setbtnval9(9)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>9</button>

                  </div>
                </div>
                <div className='row mt-2' >
                  <div className='col-lg-4 col-4 p-0'>
                    <button className='btn btn-danger fontmaaray' style={{ color: "white", cursor: "pointer", width: "82%", height: "100%" }} onClick={closepricepopup}>غلق</button>

                  </div>

                  <div className='col-4 p-0'  >
                    <button className='btn btn-secondary fontmaaray' id='btn10' onClick={() => setbtnval10(0)} style={{ cursor: "pointer", width: "82%", height: "100%", fontSize: "30px" }}>0</button>

                  </div>

                  <div className='col-lg-4 col-4 p-0'>
                    {console.log("a.len", array)}
                    <button className='btn btn-warning' style={{ height: "100%", width: "83%" }} onClick={backspacebtn} >
                      <IoBackspaceSharp style={{ fontSize: "40px", color: "white" }}> </IoBackspaceSharp>
                    </button>
                  </div>



                </div>
                {iteminbtn.itemQty == 0 ?
                  <div className='row mt-2'>

                    <div className='col-12' style={{ height: "50px" }}>
                      <button className='btn  btn-success fontmaaray' style={{ height: "93%", backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%" }} onClick={() => showarrayleng(iteminbtn)}>موافق</button>

                    </div>

                  </div> :
                  <div className='row mt-2'>
                    <div className='col-6' style={{ height: "50px" }}>

                      <button className='btn btn-warning w-100' style={{ height: "93%", color: "#D10000", fontWeight: "bold" }} onClick={() => zeroitem2(iteminbtn, sectionid)} >
                        حذف الصنف
                      </button>

                    </div>
                    <div className='col-6' style={{ height: "50px" }}>
                      <button className='btn  btn-success fontmaaray' style={{ height: "93%", backgroundColor: "rgb(0, 128, 0)", color: "white", cursor: "pointer", width: "100%" }} onClick={() => showarrayleng(iteminbtn)}>موافق</button>

                    </div>

                  </div>}
              </div>
            </div>
          </div>
          : ""}
        <div className='container-fluid mt-3'>
          <div className='row justify-content-center'>
            <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 ' id='orderprint1'>
              <div className='container-fluid bord'>

                <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                  <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                  <h1 className='text-light col-10 text-center fontmaaray mt-1'>الطلبيه</h1>
                </div>
                {/*table of order item */}
                <div className='row  fontmaaray ' >
                  <div className='row  justify-content-center fontmaaray'>
                    <h6 className='col-lg-5  col-6  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                    {/* <h6  className='col-lg-3  col-2  fontmaaray'  style={{'color':props.headerObj.fontcolor}}>سعر</h6>  */}

                    <h6 className='col-lg-3 col-3 p-0 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                    <h6 className='col-lg-3 col-3 p-0  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>  سعر/اجمالي </h6>
                    <div className='col-1'>


                    </div>
                  </div>
                  {/* <h6  className='col-1   fontmaaray' style={{'color':props.headerObj.fontcolor}}>خصم</h6> */}

                  {/*maping from list to show order details [name, quntity,total discount,total ] */}
                  {props.OrderItemLst.map(order => (

                    <div className='row justify-content-center fontmaaray' id='myTable'>
                      <div className='col-5'>
                        <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemName}</h6>


                      </div>
                      <div className='col-3'>
                        <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                      </div>
                      <div className='col-3 '>
                        <div className='row'>
                          <div className='col-12'>
                            <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.total.toLocaleString()}/{(order.itemDiscountedPrice.toLocaleString())}</h6>

                          </div>

                          {/* <div className='col-6'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} ></h6>

</div> */}
                        </div>

                      </div>
                      {order.itemRecipes != null && order.itemRecipes.length > 0 ? <div className='col-1'>

                      </div> :
                        <div className='col-1' onClick={() => handleClick(order)}>
                          <button className='btn  w-100' style={{ border: "1px solid #198754", height: "25px", marginTop: "-7px", marginRight: "-4px" }}><MdModeEditOutline style={{ marginTop: "-14px", marginRight: "-8px", color: "#198754" }} ></MdModeEditOutline></button>

                        </div>
                      }



                      {/* <h6 className='col-1 fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6> */}
                    </div>


                  ))}
                  {/*total of order  */}


                </div>




                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (
                  <div className='row justify-content-center card mt-2 fontmaaray' id='myTablephone'>

                    <div className='col-12 mt-2'>
                      <div className='row'>
                        <div className='col-6'>
                          <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>

                        </div>
                        <div className='col-2 QtyInMobile p-0'>
                          <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{parseInt(order.Qty)}</h6>

                        </div>
                        <div className='col-4'>
                          <div className='d-flex'>
                            <div className='col-8'>
                              <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{order.itemSalesPrice.toLocaleString()}</h6>

                            </div>
                            {order.itemRecipes != null && order.itemRecipes.length > 0 ? <div className='col-4 p-0 ' id={order.itemID} ></div> : <div className='col-4 p-0 ' id={order.itemID} onClick={() => handleClick(order)} >
                              <button className='btn  w-100' style={{ border: "1px solid #198754", height: "25px", marginTop: "-7px", marginRight: "-4px" }}><MdModeEditOutline style={{ marginTop: "-14px", marginRight: "-8px", color: "#198754" }} ></MdModeEditOutline></button>
                            </div>}


                          </div>
                          <div className='col-12 bg-success ' style={{ borderRadius: "4px" }}>
                            <h6 className=' fontmaaray ' style={{ fontSize: "20px", color: "white" }} >{order.total.toLocaleString()}</h6>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='col-12'>
<div className='row'>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>

</div>oo
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>

</div>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.netprice).toFixed(2)}</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{parseInt(order.total).toFixed(2) }</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"11px"}}>


<button className='w-100 fontmaaray btn btn-success text-white'   onClick={() => showpopupprice(iteminbtn,iteminbtn.itemSalesPrice)}   style={{'color':props.headerObj.fontcolor}} >تعديل</button>

</div>
</div>
</div> */}
                  </div>


                ))}
                {/*total of order  */}




              </div>
              <div className='container-fluid mt-2'>
                <div className='row' >
                  <div className='row'>
                    <h6 className='col-lg-2 fs-4 col-5 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                    <h6 className='col-lg-2 fs-4 col-3 fontmaaray ' style={{ 'text-align': 'left', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h6>
                    <hr className='mt-3 col-lg-4 col-3 fontmaaray' style={{ 'margin-right': '6%' }}></hr>

                  </div>

                  <div className='row '>
                    <div className='col-lg-2'></div>
                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> الوفر</h6>
                    <hr className='mt-3 col-8 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {parseInt(total.totaldiscount).toFixed(2)}</h6>

                  </div>
                  <div className='row'>
                    <div className='col-lg-2'></div>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> النقل</h6>
                    <hr className='mt-3 col-lg-7 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    {props.delvVal == undefined ?
                      <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                      :
                      <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



                    }
                  </div>
                  {total.Nettotal == 0 ?

                    <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>




                    : <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>

                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>}

                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-3' onClick={props.showsalemanpopup}>
                    <div className='row' style={{ color: "red" }}>
                      <div className='col-1 p-0'>
                        <AiOutlineUser style={{ fontSize: "22px", marginTop: "28%" }}></AiOutlineUser>

                      </div>
                      {props.saleIdVP == null || props.saleIdVP == undefined ? <div className='col-11 p-0'>
                        <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}> اختر ممثل مبيعات </p>

                      </div> :
                        <div className='col-11 p-0'>
                          <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}>{props.saleIdVP} </p>

                        </div>}

                    </div>
                  </div>
                </div>
                {props.popupsaleman == true ?
                  <div className='col-lg-6 col-10 card popupsalesman'>
                    <div className='row'>
                      <div className='col-lg-11 col-10 fontmaaray'>
                        <p>قائمه ممثل المبيعات</p>
                      </div>
                      <div className='col-lg-1 col-2' onClick={props.hidealemanpopup}>
                        <AiOutlineClose className='text-danger'></AiOutlineClose>
                      </div>

                    </div>
                    <div className='col-12'>

                      {salesman && salesman.map((mansale) => (
                        // onClick={()=> {setsale(mansale.firstName + " " + mansale.lastName)}} 

                        <div className='col-12' onClick={() => { setsale(mansale.firstName + " " + mansale.lastName, mansale.salesmanID) }}  >
                          <p style={{ textAlign: "right", marginRight: "2%" }}>{mansale.firstName + " " + mansale.lastName}</p>

                        </div>


                      ))}

                    </div>
                    {/* <div className='col-12' style={{position:"absolute",bottom:"1px"}}>
<div className='row justify-content-center'  >
<div className='col-lg-3 col-4'>
<button className='btn btn-success w-100'>
حفظ
</button>
</div>
<div className='col-lg-3 col-4'>
<button className='btn btn-danger w-100'>
غلق
</button>
</div>
</div>
</div> */}


                  </div> : ""}

              </div>

            </div>
            <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 ' id='orderprint2'>
              <div className='container-fluid bord'>

                <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                  <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                  <h1 className='text-light col-10 text-center fontmaaray mt-1'>الطلبيه</h1>
                </div>
                {/*table of order item */}
                <div className='row  fontmaaray ' >
                  <div className='row  justify-content-center fontmaaray'>
                    <h6 className='col-lg-6  col-6  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                    {/* <h6  className='col-lg-3  col-2  fontmaaray'  style={{'color':props.headerObj.fontcolor}}>سعر</h6>  */}

                    <h6 className='col-lg-3 col-3 p-0 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                    <h6 className='col-lg-3 col-3 p-0  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>  سعر/اجمالي </h6>

                  </div>
                  {/* <h6  className='col-1   fontmaaray' style={{'color':props.headerObj.fontcolor}}>خصم</h6> */}

                  {/*maping from list to show order details [name, quntity,total discount,total ] */}
                  {props.OrderItemLst.map(order => (

                    <div className='row justify-content-center fontmaaray' id='myTable'>
                      <div className='col-6'>
                        <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemName}</h6>


                      </div>
                      <div className='col-3'>
                        <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                      </div>
                      <div className='col-3 '>
                        <div className='row'>
                          <div className='col-12'>
                            <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.total.toLocaleString()}/{(order.itemDiscountedPrice.toLocaleString())}</h6>

                          </div>

                          {/* <div className='col-6'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} ></h6>

</div> */}
                        </div>

                      </div>




                      {/* <h6 className='col-1 fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6> */}
                    </div>


                  ))}
                  {/*total of order  */}


                </div>




                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (
                  <div className='row justify-content-center card mt-2 fontmaaray' id='myTablephone'>

                    <div className='col-12 mt-2'>
                      <div className='row'>
                        <div className='col-6'>
                          <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>

                        </div>


                        <div className='col-2 QtyInMobile p-0'>
                          <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                        </div>



                        {/* <div className='col-2 QtyInMobile'>
<div className='row'>
<div className='col-12'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>
</div>
<div className='col-12 p-0'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>[{order.eqvName}]</h6>
</div>
</div>


</div> */}

                        <div className='col-4'>
                          <div className='d-flex'>
                            <div className='col-8'>
                              <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{order.itemSalesPrice.toLocaleString()}</h6>

                            </div>
                            <div className='col-4 p-0 ' id={order.itemID} onClick={() => handleClick(order)} >
                              <button className='btn  w-100' style={{ border: "1px solid #198754", height: "25px", marginTop: "-7px", marginRight: "-4px" }}><MdModeEditOutline style={{ marginTop: "-14px", marginRight: "-8px", color: "#198754" }} ></MdModeEditOutline></button>
                            </div>
                          </div>
                          <div className='col-12 bg-success ' style={{ borderRadius: "4px" }}>
                            <h6 className=' fontmaaray ' style={{ fontSize: "20px", color: "white" }} >{order.total.toLocaleString()}</h6>
                          </div>
                        </div>





                      </div>

                    </div>

                    {/* <div className='col-12'>
<div className='row'>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>

</div>oo
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>

</div>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.netprice).toFixed(2)}</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{parseInt(order.total).toFixed(2) }</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"11px"}}>


<button className='w-100 fontmaaray btn btn-success text-white'   onClick={() => showpopupprice(iteminbtn,iteminbtn.itemSalesPrice)}   style={{'color':props.headerObj.fontcolor}} >تعديل</button>

</div>
</div>
</div> */}
                  </div>


                ))}
                {/*total of order  */}




              </div>
              <div className='container-fluid mt-2'>
                <div className='row' >
                  <div className='row'>
                    <div className='col-lg-1'></div>
                    <h6 className='col-lg-2 fs-4 col-6 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                    <h6 className='col-lg-4 fs-4 col-3 fontmaaray ' style={{ marginRight: "40%", 'text-align': 'left', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h6>

                  </div>

                  <div className='row '>
                    <div className='col-lg-2'></div>
                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> الوفر</h6>
                    <hr className='mt-3 col-8 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {parseInt(total.totaldiscount).toFixed(2)}</h6>

                  </div>
                  <div className='row'>
                    <div className='col-lg-2'></div>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> النقل</h6>
                    <hr className='mt-3 col-lg-7 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>
                    {props.delvVal == undefined ?
                      <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                      :
                      <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(props.delvVal).toFixed(2)}</h6>



                    }
                  </div>
                  {total.Nettotal == 0 ?

                    <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>

                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>




                    : <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>

                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>}

                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-12' onClick={props.showsalemanpopup}>
                    <div className='row' style={{ color: "red" }}>
                      <div className='col-1 p-0'>
                        <AiOutlineUser style={{ fontSize: "22px", marginTop: "28%" }}></AiOutlineUser>

                      </div>
                      {props.saleIdVP == null || props.saleIdVP == undefined ? <div className='col-11 p-0'>
                        <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}> اختر ممثل مبيعات </p>

                      </div> : <div className='col-11 p-0'>
                        <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}>{props.saleIdVP} </p>

                      </div>}

                    </div>
                  </div>
                </div>
                {props.popupsaleman == true ?
                  <div className='col-lg-6 col-10 card popupsalesman'>
                    <div className='row'>
                      <div className='col-lg-11 col-10 fontmaaray'>
                        <p>قائمه ممثل المبيعات</p>
                      </div>
                      <div className='col-lg-1 col-2' onClick={props.hidealemanpopup}>
                        <AiOutlineClose className='text-danger'></AiOutlineClose>
                      </div>

                    </div>
                    <div className='col-12'>

                      {salesman && salesman.map((mansale) => (
                        // onClick={()=> {setsale(mansale.firstName + " " + mansale.lastName)}} 

                        <div className='col-12' onClick={() => { setsale(mansale.firstName + " " + mansale.lastName) }}  >
                          <p style={{ textAlign: "right", marginRight: "2%" }}>{mansale.firstName + " " + mansale.lastName}</p>

                        </div>


                      ))}

                    </div>
                    {/* <div className='col-12' style={{position:"absolute",bottom:"1px"}}>
<div className='row justify-content-center'  >
<div className='col-lg-3 col-4'>
<button className='btn btn-success w-100'>
حفظ
</button>
</div>
<div className='col-lg-3 col-4'>
<button className='btn btn-danger w-100'>
غلق
</button>
</div>
</div>
</div> */}


                  </div> : ""}


              </div>

            </div>


          </div>

          <div className='row justify-content-center btnprientdesktop'>
            {loading == true && isDesktopOrLaptop ?
              <div className='col-lg-3 col-12'>
                <button className='btn  w-100 text-white fontmaaray' style={{ background: "#FFC000", height: "54px", fontSize: "27px" }}>
                  <div class="spinner"></div>
                </button>
              </div>




              :
              ""

            }

          </div>




        </div>
        <div className='container-fluid mt-3'>
          <div className='row justify-content-center'>
            <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 ' id='orderprint11' style={{ display: "none" }}>
              <div className='container-fluid bord'>

                <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                  <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                  <h1 className='text-light col-10 text-center fontmaaray mt-1'>الطلبيه</h1>
                </div>
                {/*table of order item */}
                <div className='row  fontmaaray ' >
                  <div className='row  justify-content-center fontmaaray'>
                    <h6 className='col-lg-6  col-6  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                    {/* <h6  className='col-lg-3  col-2  fontmaaray'  style={{'color':props.headerObj.fontcolor}}>سعر</h6>  */}

                    <h6 className='col-lg-3 col-3 p-0 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                    <h6 className='col-lg-3 col-3 p-0  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>  سعر/اجمالي </h6>

                  </div>
                  {/* <h6  className='col-1   fontmaaray' style={{'color':props.headerObj.fontcolor}}>خصم</h6> */}

                  {/*maping from list to show order details [name, quntity,total discount,total ] */}
                  {props.OrderItemLst.map(order => (

                    <div className='row justify-content-center fontmaaray' id='myTable'>
                      <div className='col-12'>
                        <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemName}</h6>


                      </div>
                      <div className='col-3'>
                        <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                      </div>
                      <div className='col-3 '>
                        <div className='row'>
                          <div className='col-12'>
                            <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.total.toLocaleString()}/{(order.itemDiscountedPrice.toLocaleString())}</h6>

                          </div>

                          {/* <div className='col-6'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} ></h6>

</div> */}
                        </div>

                      </div>




                      {/* <h6 className='col-1 fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6> */}
                    </div>


                  ))}
                  {/*total of order  */}


                </div>




                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (
                  <div className='row justify-content-center  mt-2 fontmaaray' style={{ borderBottom: "1px solid lightgray" }} id='myTablephone'>

                    <div className='col-12 mt-2'>
                      <div className='row'>
                        <div className='col-12'>
                          <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>

                        </div>
                        <div className='col-6'>

                        </div>

                        <div className='col-2 QtyInMobile p-0'>
                          <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                        </div>





                        <div className='col-4'>
                          <div className='d-flex'>
                            <div className='col-8'>
                              <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{order.itemSalesPrice.toLocaleString()}</h6>

                            </div>

                          </div>
                          <div className='col-12 bg-success ' style={{ borderRadius: "4px" }}>
                            <h6 className=' fontmaaray ' style={{ fontSize: "20px", color: "white" }} >{order.total.toLocaleString()}</h6>
                          </div>
                        </div>





                      </div>

                    </div>

                    {/* <div className='col-12'>
<div className='row'>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>

</div>oo
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>

</div>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.netprice).toFixed(2)}</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{parseInt(order.total).toFixed(2) }</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"11px"}}>


<button className='w-100 fontmaaray btn btn-success text-white'   onClick={() => showpopupprice(iteminbtn,iteminbtn.itemSalesPrice)}   style={{'color':props.headerObj.fontcolor}} >تعديل</button>

</div>
</div>
</div> */}
                  </div>


                ))}
                {/*total of order  */}




              </div>
              <div className='container-fluid mt-2'>
                <div className='row' >
                  <div className='row'>
                    <h6 className='col-lg-2 fs-4 col-5 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                    <h6 className='col-lg-2 fs-4 col-3 fontmaaray ' style={{ 'text-align': 'left', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h6>
                    <hr className='mt-3 col-lg-4 col-3 fontmaaray' style={{ 'margin-right': '6%' }}></hr>

                  </div>

                  <div className='row '>
                    <div className='col-lg-2'></div>
                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> الوفر</h6>
                    <hr className='mt-3 col-8 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {parseInt(total.totaldiscount).toFixed(2)}</h6>

                  </div>
                  <div className='row'>
                    <div className='col-lg-2'></div>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> النقل</h6>
                    <hr className='mt-3 col-lg-7 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{parseInt(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                  </div>
                  {total.Nettotal == 0 ?

                    <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>
                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>




                    : <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>

                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>}

                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-3' onClick={props.showsalemanpopup}>
                    <div className='row' style={{ color: "red" }}>
                      <div className='col-1 p-0'>
                        <AiOutlineUser style={{ fontSize: "22px", marginTop: "28%" }}></AiOutlineUser>

                      </div>
                      {props.saleIdVP == null || props.saleIdVP == undefined ? <div className='col-11 p-0'>
                        <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}> اختر ممثل مبيعات </p>

                      </div> :
                        <div className='col-11 p-0'>
                          <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}>{props.saleIdVP} </p>

                        </div>}

                    </div>
                  </div>
                </div>
                {props.popupsaleman == true ?
                  <div className='col-lg-6 col-10 card popupsalesman'>
                    <div className='row'>
                      <div className='col-lg-11 col-10 fontmaaray'>
                        <p>قائمه ممثل المبيعات</p>
                      </div>
                      <div className='col-lg-1 col-2' onClick={props.hidealemanpopup}>
                        <AiOutlineClose className='text-danger'></AiOutlineClose>
                      </div>

                    </div>
                    <div className='col-12'>

                      {salesman && salesman.map((mansale) => (
                        // onClick={()=> {setsale(mansale.firstName + " " + mansale.lastName)}} 

                        <div className='col-12' onClick={() => { setsale(mansale.firstName + " " + mansale.lastName, mansale.salesmanID) }}  >
                          <p style={{ textAlign: "right", marginRight: "2%" }}>{mansale.firstName + " " + mansale.lastName}</p>

                        </div>


                      ))}

                    </div>
                    {/* <div className='col-12' style={{position:"absolute",bottom:"1px"}}>
<div className='row justify-content-center'  >
<div className='col-lg-3 col-4'>
<button className='btn btn-success w-100'>
حفظ
</button>
</div>
<div className='col-lg-3 col-4'>
<button className='btn btn-danger w-100'>
غلق
</button>
</div>
</div>
</div> */}


                  </div> : ""}

              </div>

            </div>
            <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 ' id='orderprint2'>
              <div className='container-fluid bord'>

                <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
                  <h1 className='col-1'>  <BsCart2 className=' text-white fs-1 mt-2' />  </h1>
                  <h1 className='text-light col-10 text-center fontmaaray mt-1'>الطلبيه</h1>
                </div>
                {/*table of order item */}
                <div className='row  fontmaaray ' >
                  <div className='row  justify-content-center fontmaaray'>
                    <h6 className='col-lg-6  col-6  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                    {/* <h6  className='col-lg-3  col-2  fontmaaray'  style={{'color':props.headerObj.fontcolor}}>سعر</h6>  */}

                    <h6 className='col-lg-3 col-3 p-0 fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                    <h6 className='col-lg-3 col-3 p-0  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>  سعر/اجمالي </h6>

                  </div>
                  {/* <h6  className='col-1   fontmaaray' style={{'color':props.headerObj.fontcolor}}>خصم</h6> */}

                  {/*maping from list to show order details [name, quntity,total discount,total ] */}
                  {props.OrderItemLst.map(order => (

                    <div className='row justify-content-center fontmaaray' id='myTable'>
                      <div className='col-6'>
                        <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemName}</h6>


                      </div>
                      <div className='col-3'>
                        <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                      </div>
                      <div className='col-3 '>
                        <div className='row'>
                          <div className='col-12'>
                            <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.total.toLocaleString()}/{(order.itemDiscountedPrice.toLocaleString())}</h6>

                          </div>

                          {/* <div className='col-6'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} ></h6>

</div> */}
                        </div>

                      </div>




                      {/* <h6 className='col-1 fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6> */}
                    </div>


                  ))}
                  {/*total of order  */}


                </div>




                {/*maping from list to show order details [name, quntity,total discount,total ] */}
                {props.OrderItemLst.map(order => (
                  <div className='row justify-content-center card mt-2 fontmaaray' id='myTablephone'>

                    <div className='col-12 mt-2'>
                      <div className='row'>
                        <div className='col-6'>
                          <h6 className='fontmaaray ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>

                        </div>


                        <div className='col-2 QtyInMobile p-0'>
                          <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }}>{order.itemQty}</h6>

                        </div>



                        {/* <div className='col-2 QtyInMobile'>
<div className='row'>
<div className='col-12'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>
</div>
<div className='col-12 p-0'>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>[{order.eqvName}]</h6>
</div>
</div>


</div> */}

                        <div className='col-4'>
                          <div className='d-flex'>
                            <div className='col-8'>
                              <h6 className=' fontmaaray ' style={{ 'color': props.headerObj.fontcolor }} >{order.itemSalesPrice.toLocaleString()}</h6>

                            </div>
                            <div className='col-4 p-0 ' id={order.itemID} onClick={() => handleClick(order)} >
                              <button className='btn  w-100' style={{ border: "1px solid #198754", height: "25px", marginTop: "-7px", marginRight: "-4px" }}><MdModeEditOutline style={{ marginTop: "-14px", marginRight: "-8px", color: "#198754" }} ></MdModeEditOutline></button>
                            </div>
                          </div>
                          <div className='col-12 bg-success ' style={{ borderRadius: "4px" }}>
                            <h6 className=' fontmaaray ' style={{ fontSize: "20px", color: "white" }} >{order.total.toLocaleString()}</h6>
                          </div>
                        </div>





                      </div>

                    </div>

                    {/* <div className='col-12'>
<div className='row'>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>

</div>oo
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className='fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>

</div>
<div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.netprice).toFixed(2)}</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
<h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{parseInt(order.total).toFixed(2) }</h6>

</div>
<div className='col-3 p-0' style={{marginBottom:"11px"}}>


<button className='w-100 fontmaaray btn btn-success text-white'   onClick={() => showpopupprice(iteminbtn,iteminbtn.itemSalesPrice)}   style={{'color':props.headerObj.fontcolor}} >تعديل</button>

</div>
</div>
</div> */}
                  </div>


                ))}
                {/*total of order  */}




              </div>
              <div className='container-fluid mt-2'>
                <div className='row' >
                  <div className='row'>
                    <div className='col-lg-1'></div>
                    <h6 className='col-lg-2 fs-4 col-6 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>  عدد الأصناف </h6>
                    <h6 className='col-lg-4 fs-4 col-3 fontmaaray ' style={{ marginRight: "40%", 'text-align': 'left', 'color': props.headerObj.fontcolor }}>({Number(props.OrderItemLst.length).toFixed(2)})</h6>

                  </div>

                  <div className='row '>
                    <div className='col-lg-2'></div>
                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> الوفر</h6>
                    <hr className='mt-3 col-8 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> {parseInt(total.totaldiscount).toFixed(2)}</h6>

                  </div>
                  <div className='row'>
                    <div className='col-lg-2'></div>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}> النقل</h6>
                    <hr className='mt-3 col-lg-7 fontmaaray' style={{ 'width': '60%', 'color': 'white' }}></hr>

                    <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{parseInt(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                  </div>
                  {total.Nettotal == 0 ?

                    <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>

                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>




                    : <div className='row'>
                      <div className='col-lg-2'></div>

                      <h6 className='col-2 fs-4 fontmaaray' style={{ 'text-align': 'right', 'white-space': 'nowrap', 'color': props.headerObj.fontcolor }}>صافي الإجمالي </h6>
                      <hr className='mt-3 col-7 fontmaaray' style={{ 'color': 'white', 'width': '51.5%' }}></hr>

                      {props.delvVal == undefined ?
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>

                        :
                        <h6 className='col-1 fs-4 fontmaaray' style={{ 'text-align': 'right', 'color': props.headerObj.fontcolor }}>{Number(total.Nettotal + props.delvVal).toFixed(2)}</h6>
                      }
                    </div>}

                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-12' onClick={props.showsalemanpopup}>
                    <div className='row' style={{ color: "red" }}>
                      <div className='col-1 p-0'>
                        <AiOutlineUser style={{ fontSize: "22px", marginTop: "28%" }}></AiOutlineUser>

                      </div>
                      {props.saleIdVP == null || props.saleIdVP == undefined ? <div className='col-11 p-0'>
                        <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}> اختر ممثل مبيعات </p>

                      </div> : <div className='col-11 p-0'>
                        <p className='fs-4 fontmaaray "' style={{ textAlign: "right", cursor: "pointer" }}>{props.saleIdVP} </p>

                      </div>}

                    </div>
                  </div>
                </div>
                {props.popupsaleman == true ?
                  <div className='col-lg-6 col-10 card popupsalesman'>
                    <div className='row'>
                      <div className='col-lg-11 col-10 fontmaaray'>
                        <p>قائمه ممثل المبيعات</p>
                      </div>
                      <div className='col-lg-1 col-2' onClick={props.hidealemanpopup}>
                        <AiOutlineClose className='text-danger'></AiOutlineClose>
                      </div>

                    </div>
                    <div className='col-12'>

                      {salesman && salesman.map((mansale) => (
                        // onClick={()=> {setsale(mansale.firstName + " " + mansale.lastName)}} 

                        <div className='col-12' onClick={() => { setsale(mansale.firstName + " " + mansale.lastName) }}  >
                          <p style={{ textAlign: "right", marginRight: "2%" }}>{mansale.firstName + " " + mansale.lastName}</p>

                        </div>


                      ))}

                    </div>
                    {/* <div className='col-12' style={{position:"absolute",bottom:"1px"}}>
<div className='row justify-content-center'  >
<div className='col-lg-3 col-4'>
<button className='btn btn-success w-100'>
حفظ
</button>
</div>
<div className='col-lg-3 col-4'>
<button className='btn btn-danger w-100'>
غلق
</button>
</div>
</div>
</div> */}


                  </div> : ""}


              </div>

            </div>


          </div>

          <div className='row justify-content-center btnprientdesktop'>







            <div className='col-lg-3 col-12 '>
              <button className='btn  w-100 text-white fontmaaray ' style={{ background: "#FFC000", height: "54px", fontSize: "27px" }} onClick={createScreenshotPDF}>طباعه</button>
            </div>



          </div>
          {loading == true && isTabletOrMobile ?
            <div className='col-lg-3 col-12'>
              <button className='btn  w-100 text-white fontmaaray' style={{ background: "#FFC000", height: "54px", fontSize: "27px" }}>
                <div class="spinner"></div>
              </button>
            </div>

            :
            <div className='row justify-content-center btnprientmobile'>
              <div className='col-lg-3 col-12'>
                <button className='btn  w-100 text-white fontmaaray' style={{ background: "#FFC000", height: "54px", fontSize: "27px" }} onClick={createScreenshotPDFMob}>طباعه</button>
              </div>
            </div>}




        </div>

      </div>
    </div>
  );

};

export default BasicExample;
