import { BsCart2 } from "react-icons/bs";
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { MdModeEditOutline } from "react-icons/md";
import './print.css';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});



function Basicprint(props) {
    
    var LS = localStorage.getItem('objtotal');
    const [total,settotal]=useState(JSON.parse(LS));
    function print(){
    console.log(window.innerWidth)
    if(window.innerWidth<431){
        const x=document.getElementById("myTable")
        ReactPDF.renderToStream(<Document />);

//         x.remove()
//      window.print()
// props.handleprint(false)
    }
    else{
        const x=document.getElementById("myTablephone")
        ReactPDF.renderToStream(<Document />);

//         x.remove()
//      window.print()
// props.handleprint(false)
    } 
    }

    
    function returnmain(){
        props.handleprint(false)

    }
console.log(props.handleprint)

return(
  <div className='container-fluid mt-3'>
  <div className='row justify-content-center'> 
  <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  <div className='container-fluid bord'>
  
      <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
      <h1 className='col-1'>  <BsCart2  className=' text-white fs-1 mt-2' />  </h1>   
          <h1 className='text-light col-10 text-center fontmaaray mt-1'>الطلبيه</h1>
          </div>
          {/*table of order item */}  
  <div className='row  fontmaaray justify-content-center' id='myTable'>
              
    <h6  className='col-1  fontmaaray' style={{'color':props.headerObj.fontcolor}}>كميه</h6>
      <h6  className='col-4   fontmaaray'  style={{'color':props.headerObj.fontcolor}}>صنف</h6>
      <h6  className='col-2   fontmaaray' style={{'color':props.headerObj.fontcolor}}>خصم</h6>
      <h6  className='col-3   fontmaaray'  style={{'color':props.headerObj.fontcolor}}>سعر</h6> 
      <h6  className='col-2   fontmaaray' style={{'color':props.headerObj.fontcolor}}>  إجمالي </h6>
  
  {/*maping from list to show order details [name, quntity,total discount,total ] */}
  {props.OrderItemLst.map(order => (
  <div className='row justify-content-center fontmaaray'>
  
  <h6 className='col-1 fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>
  <h6 className='col-4 fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{order.itemName}</h6>
  <h6 className='col-2 fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>
  <h6 className='col-3 fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{(order.netprice.toLocaleString())}</h6>
  <h6 className='col-2 fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{order.total.toLocaleString()}</h6>
  </div>
  
  
  ))}
  {/*total of order  */}
  
  
   </div>
  
              
  
  
  {/*maping from list to show order details [name, quntity,total discount,total ] */}
  {props.OrderItemLst.map(order => (
  <div className='row justify-content-center card mt-2 fontmaaray' id='myTablephone'>
  
  <div className='col-12 mt-2'> 
  <div className='row'>
  <div className='col-2 p-0'>
  {/* style={{'color':props.headerObj.fontcolor}} */}
  <h6 className='fontmaaray ' style={{fontWeight:"bold",color:"#bb0202"}}  >[{parseInt(order.itemQty)}]</h6>
  
  </div>
  <div className='col-7 p-0'>
  <h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor,textAlign:"right"}}>{order.itemName}</h6>
  
  </div>
  <div className='col-2 p-0'>
  
  <h6 className=' fontmaaray '  style={{color:"#bb0202",fontWeight:"bold"}}>{order.total.toLocaleString()}</h6>
  
  </div>
  <div className='col-1 p-0 ' id={order.itemID} >
  <button className='btn btn-success w-100'  style={{height:"25px",marginTop:"-7px",marginRight:"-4px"}}><MdModeEditOutline style={{marginTop:"-14px",marginRight:"-6px"}} ></MdModeEditOutline></button>
  </div>
  
  </div>
  
  </div>
  {order.itemDiscountAmount==0?"":
  
  <div className='col-12'>
  <div className='row'>
  <div className='col-4 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <div className='container-fluid'>
  <div className='row'>
  <div className='col-5 p-0'>
  <p>سعر</p>
  </div>
  <div className='col-7 p-0'>
  <p style={{textAlign:"right"}}>{parseInt(order.itemSalesPrice).toFixed(2)}
  </p>
  </div>
  </div>
  </div>
  
  </div>
  
  <div className='col-4 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <div className='container-fluid'>
  <div className='row'>
  <div className='col-5 p-0'>
  <p>خصم</p>
  </div>
  <div className='col-7 p-0'>
  <p style={{textAlign:"right"}}>{parseInt(order.itemDiscountAmount).toFixed(2)}</p>
  </div>
  </div>
  </div>
  
  </div>
  
  <div className='col-4 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <div className='container-fluid'>
  <div className='row'>
  <div className='col-5 p-0'>
  <p>صافي</p>
  </div>
  <div className='col-7 p-0'>
  <p style={{textAlign:"right"}}>{parseInt(order.itemSalesPrice).toFixed(2)}</p>
  </div>
  </div>
  </div>
  
  </div>
  
  {/* <div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className='fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>
  
  </div> */}
  {/* <div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.netprice).toFixed(2)}</h6>
  
  </div> */}
  {/* <div className='col-3 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{parseInt(order.total).toFixed(2) }</h6>
  
  </div> */}
  {/* <div className='col-3 p-0' style={{marginBottom:"11px"}}>
  
  
  <button className='w-100 fontmaaray btn btn-success text-white'   onClick={() => showpopupprice(iteminbtn,iteminbtn.itemSalesPrice)}   style={{'color':props.headerObj.fontcolor}} >تعديل</button>
  
  </div> */}
  </div>
  </div>
  
  
  
  
  
  }
  {/* <div className='col-12'>
  <div className='row'>
  <div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemQty)}</h6>
  
  </div>
  <div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className='fontmaaray  '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.itemDiscountAmount).toFixed(2)}  </h6>
  
  </div>
  <div className='col-2 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}}>{parseInt(order.netprice).toFixed(2)}</h6>
  
  </div>
  <div className='col-3 p-0' style={{marginBottom:"0px",marginTop:"3%"}}>
  <h6 className=' fontmaaray '  style={{'color':props.headerObj.fontcolor}} >{parseInt(order.total).toFixed(2) }</h6>
  
  </div>
  <div className='col-3 p-0' style={{marginBottom:"11px"}}>
  
  
  <button className='w-100 fontmaaray btn btn-success text-white'   onClick={() => showpopupprice(iteminbtn,iteminbtn.itemSalesPrice)}   style={{'color':props.headerObj.fontcolor}} >تعديل</button>
  
  </div>
  </div>
  </div> */}
  </div>
  
  
  ))}
  {/*total of order  */}
  
  
  
  
  </div>
  <div className='container-fluid mt-2'>
  <div className='row' >
  <div className='row'>
  <h6 className='col-lg-4 fs-4 col-5 fontmaaray' style={{'text-align':'right' ,'white-space':'nowrap','color':props.headerObj.fontcolor}}>  عدد الأصناف </h6> 
  <h6 className='col-lg-2 fs-4 col-3 fontmaaray '  style={{'text-align':'left' , 'color':props.headerObj.fontcolor}}>({total.totalqut.toLocaleString()})</h6>
  <hr className='mt-3 col-lg-5 col-3 fontmaaray' style={{'margin-right': '6%'}}></hr>
  
  </div>
  
  <div className='row '>
    <h6 className='col-1 fs-4 fontmaaray' style={{'text-align':'right','color':props.headerObj.fontcolor}}> الوفر</h6>
    <hr className='mt-3 col-10 fontmaaray' style={{'width':'60%' ,'color':'white'}}></hr>
  
    <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}> {parseInt(total.totaldiscount ).toFixed(2)}</h6>
  
  </div>
  <div className='row'>
    <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}> النقل</h6>
    <hr className='mt-3 col-10 fontmaaray' style={{'width':'60%' ,'color':'white'}}></hr>
  
    <h6 className='col-1 fs-4 fontmaaray' style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}>{parseInt(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>
  
  </div>
  {total.Nettotal==0?
  
   <div className='row'>
    <h6 className='col-2 fs-4 fontmaaray' style={{'text-align':'right' ,'white-space':'nowrap','color':props.headerObj.fontcolor}}>صافي الإجمالي </h6>
    <hr className='mt-3 col-7 fontmaaray' style={{ 'color':'white','width':'51.5%'}}></hr>
  
    <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}>{(total.Nettotal+props.headerObj.defaultDeliveryPrice).toLocaleString(2) }</h6>
  
  </div>
  
  
  
  
  :    <div className='row'>
    <h6 className='col-2 fs-4 fontmaaray' style={{'text-align':'right' ,'white-space':'nowrap','color':props.headerObj.fontcolor}}>صافي الإجمالي </h6>
    <hr className='mt-3 col-7 fontmaaray' style={{ 'color':'white','width':'51.5%'}}></hr>
  
    <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}>{(total.Nettotal+props.headerObj.defaultDeliveryPrice).toLocaleString(2) }</h6>
  
  </div>}
  
  </div>
  
  
  </div>
  
  </div></div>
  <div className="col-11"  style={{position:"absolute",bottom:"10px"}}>
  <div className="row justify-content-center"  >
  
  <div className="col-lg-2 col-4">
  <button className="btn btn-danger w-100" onClick={returnmain}>
  الغاء
  </button>
  </div>
  <div className="col-lg-2 col-4">
  <button className="btn btn-success w-100" onClick={print}>
  حفظ
  </button>
  </div>
  </div>
  </div>
  </div>



)

}
   
export default  Basicprint;
  