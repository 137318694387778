import 'bootstrap/dist/css/bootstrap.css';
import './footer.css';
import { AiOutlineWhatsApp} from "react-icons/ai";
import { AiOutlineMail} from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { useState } from 'react';
import Swal from "sweetalert2";
import { AiFillPlayCircle } from "react-icons/ai";
import { HiShare } from "react-icons/hi";

import $ from 'jquery';
import { FacebookShareButton } from 'react-share';
import { TwitterShareButton } from 'react-share';
import { WhatsappShareButton } from 'react-share';
import { FaShareNodes } from "react-icons/fa";
import { FacebookMessengerShareButton } from 'react-share';
import { AiFillCopy } from "react-icons/ai";

import {
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import { ConstructionOutlined } from '@mui/icons-material';
import { useRef } from 'react';



function Footer(props){
  const safeDocument = typeof document !== 'undefined' ? document : {};
  const [url,seturl]=useState(window.location.href)
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;
  
    const [popuptijary,setpopuptijary]=useState(false)
function showpopuptijary(){
  setpopuptijary(true)
   document.body.classList.add("disable-scroll")
}
const[showagain,setshowagain]=useState(false)

const videoload = event =>{
  if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  Swal.fire({
    // icon: 'info',
    html:
      '<iframe class="iframwidth" height="300" src="https://www.youtube.com/embed/5KtYqJwpgRg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
       +'<label class="fontmaaray">عدم اظهار الفيديو مره اخري</label>'
       +'<input type="checkbox" id="cheackinvideo" >',
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:
      'موافق',
    confirmButtonAriaLabel: 'Thumbs up, great!',

  }).then((result) => {
const x=document.getElementById("cheackinvideo").checked
const Elecheck=document.getElementById("cheackinvideo")
    if (result.isConfirmed && x === true) {
      if (!body || !body.style || !scrollBlocked.current) return;

      html.style.position = '';
      html.style.overflow = '';
      body.style.position = '';
      body.style.overflow = '';
      body.style.paddingRight = '';
  
      scrollBlocked.current = false;
      $("#itemop").css("pointer-events", "visible");

      setshowagain(true)
      localStorage.setItem('showagain', true)
      Elecheck.setAttribute("checked","")
      

    }
   
  else{
    localStorage.setItem('showagain', false)
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
    $("#itemop").css("pointer-events", "visible");

  }
  
  })

  var cheackinvideo = document.getElementById("cheackinvideo");
  var showagain = localStorage.getItem('showagain')

  if(showagain == "true")
     cheackinvideo.setAttribute("checked",showagain);
  else
     cheackinvideo.removeAttribute("checked");
     localStorage.setItem('showagain', false)

}

    function closepopuptijary(){
  setpopuptijary(false)
  document.body.classList.remove("disable-scroll")

}
const openModalofshare = () => {
  $('.modalshare').removeClass("d-none");
  $('.modalshare').addClass("d-block");
  $('body').addClass("disable-scroll");
};

const closeModalofshare = () => {
  $('.modalshare').removeClass("d-block");
  $('.modalshare').addClass("d-none");
  $('body').removeClass("disable-scroll");
};
    return(
        <div className='container-fluid mt-4'>
            <div className="container-fluid">
       
       { props.headerObj.footer && <div className='row' style={{'backgroundColor':props.headerObj.footer.color}} dir="rtl">
       <div className='col-12 fs-1 '>
        <div className='row'>
<div className='col-12'>
<h3 className='mt-1 fontmaaray' style={{'color':props.headerObj.footer.fontColor}}>{props.headerObj.footer.title}</h3>

</div>


        </div>
        </div>
        <div className="row  mt-2 justify-content-center">
       {props.headerObj.footer.items.map((footer, index) => (
   <div className='col-lg-1 col-2'>
 
<a href={footer.url} className='w-100' target="_blank">
         <img className='imgoffooter' style={{height:"50px"}} src={footer.image}  ></img>  
         </a>   
   </div>
         
       ))}
       </div>
       

       
      
        </div>}
    <div className='row justify-content-center mt-2' >
<div className='col-11'>
<div className='row' >
<div className='col-lg-2 col-5' style={{marginRight:"5%",border:"1px solid lightgray",borderRadius:"6px",color:"white",background:"red"}} onClick={videoload}>
<div className='d-flex' style={{justifyContent:"center",marginTop:"8%"}}>
<div className='col-lg-9 col-5'>
<p className='fontmaaray' style={{fontSize:"19px"}}>شرح </p>
</div>
<div className='col-lg-3 col-3 p-0'>
<AiFillPlayCircle  style={{fontSize:"35px",color:"white"}}></AiFillPlayCircle>

</div>

</div>
</div>
<div className='col-lg-7 col-1'>

</div>
<div className='col-lg-2 col-5' style={{border:"1px solid lightgray",color:"white",borderRadius:"6px",background:"#0d6efd"}} onClick={openModalofshare}> 
<div className='row'>

<div className='col-6'>
<p className='fontmaaray' style={{marginTop:"23%",textAlign:"right",fontSize:"19px"}}>
  مشاركه
</p>
</div>
<div className='col-6'>
<HiShare className='' style={{marginTop:"21%",fontSize:"30px"}}> </HiShare>

</div>

</div>
</div>
</div>
<div className='row' style={{justifyContent:"center",height:"65px"}}>

<div className='col-lg-2 col-4 p-0 '>

<img onClick={showpopuptijary} style={{width:"100px",marginLeft:"7%"}} src={require("./logotijary.png")}></img>

</div>


</div>

</div>

    </div>
   
  
    {popuptijary==true?
    <div className="modal-abouttijary  d-flex justify-content-center  align-items-center" >
    <div className="modal-dialog modal-dialog-centered" >
      <div className="modal-content  ">
        <div className="modal-header">
        <div >
            <button type="button" className="btn-close btn " style={{color:"red"}}
            //  onClick={closeModal}
            onClick={closepopuptijary}
            ></button>
          </div>
          <div className="d-flex justify-content-center" style={{ width: "95%" }}>
            <h1 className="modal-title fs-2" style={{ marginTop: "10px", fontSize: "18px", color: "rgb(25, 135, 84)", fontWeight: "bold", textShadow: "rgb(191, 191, 191) 2px 4px 4px" }}>
            tijary عن 
            </h1>
          </div>
        
        </div>
        <div className="modal-body">
         <div className="d-flex flex-column justify-contnent-center align-items-center">
         <p className='text-center fontmaaray'>نظام متكامل للأداره الشاملة لمحلات البيع و متطلبات عملائهم من خلال مجموعه من التطبيقات وتكنولوجيا اداره المعلومات</p>
         <p className='text-center fontmaaray'>يقوم النظام باداره محلات البيع بما يتطلب من زياده الأرباح والتوفير وتقليص الخسائر </p>
         <p className='text-center fontmaaray mb-0'>يتركز علي ارسال التفوق الاداري و انجاح العمليه التجاريه سواء اونلاين او اوف لاين للانترنت المحل</p>
         </div>
        </div>
        <div className="modal-footer d-flex justify-content-center border-0">

          <a className="col-6 col-lg-3  p-2 fs-2" href="https://wa.me/201118118343" style={{height: "43px"}} target="_blank">
          <AiOutlineWhatsApp style={{width:"54%",marginTop:"-11%",color:"white","font-size": "40px",borderRadius:"5px",background:"#3FC751"}}></AiOutlineWhatsApp>
            {/* <i className="fa-brands fa-whatsapp" style={{ color: '#27b459' }}></i> */}
          </a>
          <a className="text-decoration-none col-6 col-lg-3" href="mailto:Support@tijary.store">
            <button type="button" className="btn p-2 px-3 " style={{ backgroundColor: "#F56902", color: "#fff" }}>
              <i className="fa-regular fa-envelope me-3"></i>
              <span>
              ارسل استفسارك 
              </span>
            </button>
          </a>
        </div>
        <div className="d-flex flex-column mt-2 ">
          <p className="mb-0  text-muted mx-1" style={{ fontSize: "0.6rem" }}>
            Developed by
          </p>
          
          <a className="text-decoration-none mb-2 " href="http://www.bedab.com/contact-arabic.html">
              <img  src={require("../footer/Bedab-Logo-Blue.png")} style={{ width: "70px" }} alt="" />
            </a>
          
          </div>
      </div>
    </div>
  </div>




// <div className='row'>
// <div className='col-lg-4 col-md-6 col-11 card popuptijary' >
// <div className='row card-header justify-content-center' >
//     <div className='col-lg-11 col-md-11 col-10'>
//    <div className='row justify-content-center'>

// <div className='col-lg-4 col-md-4 col-6'>
// <p style={{marginTop:"10px",fontSize:"18px",color:"rgb(25, 135, 84)",fontWeight:"bold",textShadow:"rgb(191, 191, 191) 2px 4px 4px"}}> tijary عن </p>
// </div>
//    </div>


//     </div>

//   <div className='col-1' style={{textAlign:"left"}}>
//   <button onClick={closepopuptijary}  className='fs-4 close text-danger btn' ><RxCross2></RxCross2> </button>

//   </div>

//   </div>
//   <div className='row justify-content-center mt-3'>
//     <div className='col-10'>
//     <p className='text-center fontmaaray'>نظام متكامل للأداره الشاملة لمحلات البيع و متطلبات عملائهم من خلال مجموعه من التطبيقات وتكنولوجيا اداره المعلومات</p>

//     </div>

//   </div>
//   <div className='row justify-content-center'>
//     <div className='col-10'>
//     <p className='text-center fontmaaray'>يقوم النظام باداره محلات البيع بما يتطلب من زياده الأرباح والتوفير وتقليص الخسائر </p>

//     </div>

//   </div>
//   <div className='row justify-content-center'>
//     <div className='col-10'>
//     <p className='text-center fontmaaray'>يتركز علي ارسال التفوق الاداري و انجاح العمليه التجاريه سواء اونلاين او اوف لاين للانترنت المحل</p>

//     </div>

//   </div>
//   <div className='row  justify-content-center ' style={{"margin-top":"6%"}}>
// <a className='col-lg-6 col-md-6 col-9 '  href="mailto:Support@tijary.store"  target="_blank">
// <button className='btn text-white fontmaaray sendyourquestion'  >
// <div className='row'>
// <div className='col-2 p-0'>
// <AiOutlineMail></AiOutlineMail>
// </div>
// <div className='col-10'>
// <p> ارسل استفسارك
// </p>
// </div>
// </div>
// </button>
// </a>
// <a className='col-2 fs-2' href="https://wa.me/201118118343"  target="_blank">
// <AiOutlineWhatsApp style={{color:"rgb(25, 135, 84)","font-size": "41px", "margin-top":"-43%"}}></AiOutlineWhatsApp>
// </a>
//   </div>

// </div>
// </div>
:""}
        </div>
        <div className="modalshare  d-none d-flex justify-content-center  align-items-center" >
        <div className="modal-dialog  modal-dialog-centered" >
          <div className="modal-content pb-3 ">
            <div className="modal-header">
            <div className="">
                <button type="button" className="btn-close btn btn-danger" onClick={closeModalofshare}></button>
              </div>
              <div className="d-flex justify-content-center" style={{ width: "95%" }}>
                <h1 className="modal-title fs-2 fontmaaray" style={{ marginTop: "10px", fontSize: "18px", color: "rgb(25, 135, 84)", fontWeight: "bold", textShadow: "rgb(191, 191, 191) 2px 4px 4px" }}>
               شارك 
                </h1>
              </div>
            
            </div>
            <div className="modal-body">
             <div className="row justify-content-center">
         <div className='col-4'>
         <FacebookShareButton
        url={`${url}`}
        quote={'Dummy text!'}
        hashtag="مجله فبصل"
      >
        <FacebookIcon size={50} round />
      </FacebookShareButton>
         </div>
<div className='col-4'>
<TwitterShareButton
  url={`${url}`}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"
>
  <TwitterIcon size={50} round />
</TwitterShareButton>
</div>
<div className='col-4'>
<WhatsappShareButton
  url={`${url}`}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"

>
<WhatsappIcon size={50} round />

</WhatsappShareButton>
</div>

{/* <div className='col-2'>
<FacebookMessengerShareButton
  url={'https://magala.zaherdairy.com/'}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"

>
<FacebookMessengerIcon size={32} round />

</FacebookMessengerShareButton>

</div> */}
             </div>
             <div className='row mt-3' style={{justifyContent:"center"}}>
<div className='col-11'>
<div className='row'>


<div className='col-12'>

<button className='btn btn-success w-100 '  onClick={() =>  navigator.clipboard.writeText(`${url}`)}


>
نسخ الرابط <AiFillCopy></AiFillCopy>
</button>
</div>
</div>
</div>
             </div>
            </div>
      
       
          </div>
        </div>
        </div>
        </div>
    )
}
export default Footer



  {/* <div className='row' style={{marginTop:"6%"}}> 
  <div className='col-12'>
  <a href='http://bedab.com/contact.html' target='_blank' className='fontmaaray' style={{fontSize:"11px","margin-top":"16px",textDecoration:"none",color:"gray"}}> develop by </a>

  </div>
  <div className='col-12'>
  <a href='http://bedab.com/contact.html' target='_blank' className='fontmaaray' style={{fontSize:"11px","margin-top":"16px",textDecoration:"none",color:"gray"}}>    bedab software
 </a>

  </div>
  </div> */}