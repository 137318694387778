import React from "react";
import {FaMicrophone} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import './record.css';
import {useState} from 'react';
import swal from 'sweetalert';
import MicRecorder from './mic-recorder';
import Swal from "sweetalert2";


// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
//to use recorder
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
 const RecordView = (props) => {
const [blobURL, setblobURL] = useState();
const [isRecording, setisRecording] = useState(false);
const [isActive, setIsActive] = useState(false);

const start = () => {
    Mp3Recorder
      .start()
      .then(() => {
        setisRecording(true);
      }).catch((e) => {
    console.log(e);
      });
};

const stop =() => {
  Mp3Recorder
    .stop()
    .getMp3()
    .then(([buffer, blob]) => {
      const blobURL = URL.createObjectURL(blob)
      setisRecording(false);
      setblobURL(blobURL);
      handleupload(blobURL);
    }).catch((e) => console.log(e));
};

const handleupload= async(blobURL)=>{
  const audioBlob = await fetch(blobURL).then((r) => r.blob());
  var fileuplod=new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' })
  localStorage.setItem("recorderLocal",JSON.stringify(fileuplod))
console.log(typeof(fileuplod))
props.setRecfile(fileuplod)
  props.flieup(fileuplod)
  console.log(fileuplod)
}

console.log("rec",localStorage.getItem("recorderLocal"))
    function resetaudio(){
      const y=document.getElementById('audio')
      if(y.src={blobURL}){
        y.src='none'
      }

    }
    // title: ".شكرا علي تسجيل الرسالة",
    // text: "يمكن الحذف و التعديل قبل الارسال",
    const handleClick = (event) => {
      event.preventDefault();
      setisRecording(true);
      start()
      setIsActive(current => !current);
    };
    function alert(){
      Swal.fire({
        title: '. شكرا علي تسجيل الرسالة ',
        text:"يمكن الحذف والتعديل قبل الأرسال",
        confirmButtonText: 'استمرار',
        customClass:{
          confirmButton:'custombuttonsweet',
          title:'customtitlesweet',
          text:'customtextsweet'
        }
      })

    .then((result) => {
      if (result.isConfirmed) {
        swal("تاكيد", {
          icon: "success",

        });

        stop();
        setisRecording(false);

      } else {
        stop();
        setisRecording(false);
      }
    });
  }

  const handleMessageChange = event => {
    // 👇️ access textarea value
   const x=event.target.value
   props.setCommentNomagazine(x)
    props.setcomment(x);
    console.log(x)
  };


  return (
    <div className="container-fluid border rounded-1 widthborder ">
      <div className="row">
      <textarea
         className='hightextarea w-100 fontmaaray'
         placeholder=' أصناف اضافيه وملاحظات
         "رسالة نصيه"
         "تسجيل صوتي " '

         dir='rtl'
        id="comment"
        name="comment"
        value={props.comment}
        style={{border:'none', 'outline': 'none'}}
        onChange={handleMessageChange}
      />

            <h4 className="text-success fontmaaray" dir="rtl">إرسال رسالة صوتية</h4>
<div className="container-fluid">
      <div className="row">
      {/* div have btn stop record and start record and sure of record */}
      <div className="voice rounded-1" >


<div className="col-1 mt-2  mx-1">
  <MdDelete className="fs-1 deletecolorbutton h-100" onClick={resetaudio}></MdDelete>
</div>

<div>

  {isRecording ? <img className="heightvoicerecord" src='https://i.ibb.co/72t4Gqz/output-onlinegiftools.gif' id="wave"></img>
  : <audio src={blobURL}  controls id="audio" className="mt-2 heightvoicerecord mx-2"/>}

</div>


 <div className="col-1  mt-3">
{isRecording ?<button className="btn btn-danger" onClick={alert}> انهاء</button> : <FaMicrophone  className="fs-1 h-75" id="btnwave" onClick={handleClick} style={{
          color: 'green'}}/>}
          </div>
      </div>
      </div>
      </div>
      </div>
      </div>
  );
};

export default RecordView;
