import 'bootstrap/dist/css/bootstrap.css';
import './header.css';

function Header(props) {

  const isValidUrl = urlString=> {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
  }
    return (
      <div className="container-fluid  highlogo">
         {props.headerObj && 
          <div >         
     <div > 
     <div className="row justify-content-center">
      
      <div className="col-3 mt-2">
        
      <img src={props.headerObj.logo} class="logo-images col-sm-3"></img>
      {console.log((props.headerObj.logo))}
      </div>
      <div className="col-9 mt-2">
        <div className="row justify-content-center">
      <h3 className="text-header card-title magazine-title fontmaaray " style={{"color":props.headerObj.headerFontColor}}>{props.headerObj.title}</h3>
    </div>
    <div className="row justify-content-center">
      <h6 className="card-title  magazine-title fontmaaray " style={{"color":props.headerObj.headerSubtitleColor}} >{props.headerObj.subtitle}</h6>
      </div>
    </div>
      </div>      
     </div>
     </div>}
      </div>
     )
      
  };
  
  export default Header;