import 'bootstrap/dist/css/bootstrap.css';
import React,{useState,useEffect} from 'react';
import './info.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useJsApiLoader } from '@react-google-maps/api';
import { IoLocation } from "react-icons/io5";
import CurrentLocation from './CurrentLocation';
import {FaRegUser} from "react-icons/fa";
import uploadFileToBlob, { isStorageConfigured } from '../recorder/azureBlob';
import {useRef} from 'react';
import { FiSend } from "react-icons/fi";
import axios from "axios";
import { GiDeliveryDrone } from "react-icons/gi";
import { useMediaQuery } from 'react-responsive';
import { MdModeEditOutline } from "react-icons/md";
import { BsCart2 } from "react-icons/bs";
import Swal from "sweetalert2";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineDeliveryDining ,MdPayment } from "react-icons/md";
import swal from 'sweetalert';
import { Diversity1TwoTone, Tune } from '@mui/icons-material';


// to error of current location 
const onError = (type, status) => console.log(type, status)

function Formuser(props){
  
  // to store audio 
  const storageConfigured = isStorageConfigured();
//to get current location 
const [CurrentLoc, setCurrentLoc] = useState('');
const [popupcontact,setpopupcontact]=useState(false)
const [submitting,setsubmitting]=useState(false)
const handleResults = (results) => {
  setCurrentLoc(results.filter((item) => {

    return (item.types.indexOf('street_address') >= 0);
    
  })[0].formatted_address)
 }
 
// to put current location in input 
const InputChange= (Value) => setCurrentLoc(Value);
const { isLoaded } = useJsApiLoader({
  id: 'google-map-script',
  googleMapsApiKey: "AIzaSyASbQBHuvWt93PcUlu1F2HAjmrboNmNS2Q"

})
//to put input value 

// object that has details of order and details of user form 
const dataform={
 consumer:{ 
  phone:'',
  name:'',
  address:'',
  email:'',
  PaymentMethod:'',
  deliveryOrder:'',
  message:'',
  comment:'',
  record:'',
  DeliveryStatus:'',
  DeliveryEmployeeID:'',
  RegionName:'',
  RegionId:''
 },
 orderItemLst:[{
  detOrder:props.orderlist
 }],
 merchantMagazineId:'',
 file:'',
//  dateofdeliverd:'',
  //  comment:''
};


// blob of record 
// const[mediaBlobUrl,setmediaBlobUrl]=useState('')
// to declare value of input form 
const[phone,setphone]=useState()
const[name,setname]=useState()
const[email,setemail]=useState()
const[dateofdeliverd,setdatedeliv]=useState()
const[paymentMethod,setpaymentMethod]=useState()
//to set value of input 
const handleChangephone = event => {
  setphone (event.target.value);

};

const closepopupcontact = event => {
  const valephone= document.getElementById("phone").value
  const valename= document.getElementById("name").value
  const valeregion= document.getElementById("theregionvalue").value
  const valeCurrentLoc= document.getElementById("CurrentLoc").value
  const valeemail= document.getElementById("email").value

 
if(valephone==""||valephone==null || valephone==undefined){
  setpopupcontact (true);
  Swal.fire("من فضلك ادخل الرقم")

}
else if(props.saleIdmanVP==undefined){
 

    setpopupcontact (true);
     Swal.fire("من فضلك ادخل ممثل المبيعات")
   
}
else if(valename==""||valename==null || valename==undefined){
  setpopupcontact (true);
  Swal.fire("من فضلك ادخل الاسم")
}
else if(valeregion==""||valeregion==null || valeregion==undefined){
  setpopupcontact (true);
  Swal.fire("من فضلك ادخل المنطقه")

}
else if(valeCurrentLoc==""||valeCurrentLoc==null || valeCurrentLoc==undefined){
  setpopupcontact (true);
  Swal.fire("من فضلك ادخل العنوان")

}
else if(valeemail==""||valeemail==null || valeemail==undefined){
  setpopupcontact (true);
  Swal.fire("من فضلك ادخل اسم المحل")

}
else{
  setpopupcontact (false);
  if (!body || !body.style || !scrollBlocked.current) return;

  html.style.position = '';
  html.style.overflow = '';
  body.style.position = '';
  body.style.overflow = '';
  body.style.paddingRight = '';

  scrollBlocked.current = false;
}

  

  

  
  // console.log("x",x)


};


const handleChangename = event => {
  setname (event.target.value);

};

const handleChangeemail = event => {
  setemail (event.target.value);  
};

const handleChangepaymentMethod = (id) => {
  setpaymentMethod (id);
};

const handleChangedate = event =>{
  setdatedeliv(event.target.value)
};

const[comment,setcomment]=useState()
const handleChangecomment = event => {
  setcomment (event.props.comment);

};

//function of submit (set value of form and audio and order )
const [TimeDelivery, setTimedelivery] = useState(0);
const[DeliveryStatus,setdeliverystatus]=useState(1);
const handleItemSubmit = (event) => {
  event.preventDefault()
  dataform.consumer.phone=phone
  dataform.consumer.name=name
  dataform.consumer.email=email  
  dataform.consumer.address=CurrentLoc
  dataform.consumer.PaymentMethod=paymentMethod
  dataform.consumer.comment=props.comment
  if(regionvalue==null ||regionvalue==undefined)
  {
    Swal.fire("يجب أدخال المنطقه")
  
  }

  dataform.consumer.deliveryOrder=regionvalue.deliveryFees
  dataform.orderItemLst=props.OrderItemLst
  dataform.merchantMagazineId=props.merchantMagazineId
  dataform.consumer.record=props.flieup
  dataform.consumer.TimeDelivery=TimeDelivery
  dataform.consumer.DeliveryStatus=DeliveryStatus
  dataform.consumer.DeliveryEmployeeID=props.saleIdmanVP
  dataform.consumer.RegionId=regionvalue.regionId
  dataform.consumer.RegionName=regionvalue.regionName
  
 if(dataform.orderItemLst.length==0)
{
  Swal.fire({
    text: "يجب اختيار المنتج  ", 
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
  })

}
  //window.location.reload();
  else if(dataform.consumer.address=='null' ||dataform.consumer.address=='' )
{
  Swal.fire({
    text: "يجب أدخال العنوان",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })
  // swal({
   
  //   text: "يجب أدخال العنوان",
  //   buttons: true,
  //   dangerMode: true,
    

  // })
}  

else if(props.saleIdmanVP==undefined){
 
  Swal.fire({
    text: "من فضلك ادخل ممثل المبيعات",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })

 
}



else if(dataform.consumer.email=='null'  || dataform.consumer.email =='')
{
  Swal.fire({
    text: "يجب أدخال اسم المحل",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })
  // swal({
   
  //   buttons: true,
  //   dangerMode: true,
    

  // })
}
else if(dataform.consumer.name=='null'||dataform.consumer.name=='')
{
  Swal.fire({
    text: "يجب أدخال الأسم",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })
  // swal({
   
  //   buttons: true,
  //   dangerMode: true,
    

  // })
}
// else if(dataform.consumer.name.match(/[0-9]/) )
// {
//   swal({
   
//     text: " يجب أدخال الأسم بدون أرقام",
//     buttons: true,
//     dangerMode: true,
//   })
// }
else if(dataform.consumer.PaymentMethod=='null' ||dataform.consumer.PaymentMethod==undefined )
{
  if(dataform.orderItemLst.length==0){
    Swal.fire({
      text: "يجب اختيار المنتج  ", 
  
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:'موافق',
   
    
    })
  }
  else{
    Swal.fire({
      text: "يجب اختيار طريقه السداد",
   
    
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:'موافق',
   
    
    })
  }
 
  // swal({
  //   text: "يجب أدخال طريقه السداد",
  //   confirmButton: true,
  //   dangerMode: true,
  //   html:"<div class='row'> <div class='col-2'><button>موافق</button></div></div>"
  // })
}
else if(dataform.consumer.phone=='null' || dataform.consumer.phone=='')
{
  Swal.fire({
    text: "يجب أدخال التليفون ",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })

}
else if(!dataform.consumer.phone.match(/^\d+$/))
{
  Swal.fire("تاكد من التليفون ")


}

else{

  onFileUpload()
  lsRememberMe()
//  createPost()
  importFile()

 
  
}


}

const handleItemSubmitmobile = (event) => {
  event.preventDefault()
  dataform.consumer.phone=phone
  dataform.consumer.name=name
  dataform.consumer.email=email  
  dataform.consumer.address=CurrentLoc
  dataform.consumer.PaymentMethod=paymentMethod
  dataform.consumer.comment=props.comment
  if(regionvalue==null ||regionvalue==undefined)
  {
    Swal.fire("يجب أدخال المنطقه")
  
  }
  dataform.consumer.deliveryOrder=regionvalue.deliveryFees
  dataform.orderItemLst=props.OrderItemLst
  dataform.merchantMagazineId=props.merchantMagazineId
  dataform.consumer.record=props.flieup
  dataform.consumer.TimeDelivery=TimeDelivery
  dataform.consumer.DeliveryStatus=DeliveryStatus
  dataform.consumer.DeliveryEmployeeID=props.saleIdmanVP
  
  if(dataform.orderItemLst.length==0)
{
  Swal.fire({
    text: "يجب اختيار المنتج  ", 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })

}
  //window.location.reload();
  if(dataform.consumer.address=='' ||dataform.consumer.address=='null' )
{
  Swal.fire({
    text: "يجب أدخال العنوان",
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
  })
  // swal({
   
  //   text: "يجب أدخال العنوان",
  //   buttons: true,
  //   dangerMode: true,
    

  // })
}  
else if(dataform.consumer.email==''||dataform.consumer.email== 'null')
{
  Swal.fire({
    text: "يجب أدخال الأيميل",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })
  // swal({
   
  //   buttons: true,
  //   dangerMode: true,
    

  // })
}
else if(dataform.consumer.name=='' ||dataform.consumer.name=='null')
{
  Swal.fire({
    text: "يجب أدخال الأسم",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })
  // swal({
   
  //   buttons: true,
  //   dangerMode: true,
    

  // })
}
// else if(dataform.consumer.name.match(/[0-9]/) )
// {
//   swal({
   
//     text: " يجب أدخال الأسم بدون أرقام",
//     buttons: true,
//     dangerMode: true,
//   })
// }
else if(!dataform.consumer.phone.match(/^\d+$/))
{
  Swal.fire("تاكد من التليفون ")


}
else if(dataform.consumer.PaymentMethod=='' ||dataform.consumer.PaymentMethod==undefined )
{
  if(dataform.orderItemLst.length==0){
    Swal.fire({
      text: "يجب اختيار المنتج  ", 
  
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:'موافق',
   
    
    })
  }
  else{
    Swal.fire({
      text: "يجب اختيار طريقه السداد",
   
    
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:'موافق',
   
    
    })
  }
 
  // swal({
  //   text: "يجب أدخال طريقه السداد",
  //   confirmButton: true,
  //   dangerMode: true,
  //   html:"<div class='row'> <div class='col-2'><button>موافق</button></div></div>"
  // })
}
else if(dataform.consumer.phone=='' ||dataform.consumer.phone=='null')
{
  Swal.fire({
    text: "يجب أدخال التليفون ",
 

    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText:'موافق',
 
  
  })
}

else{

  onFileUpload()
  lsRememberMe()
//  createPost()
  importFilemobile()

 
  
}


}
const[regionvaluecity,setregionvaluecity]=useState()
const[regionvalue,setregionvalue]=useState()
const[LSregionvalue,setLSregionvalue]=useState("-1")
const optionList=props.headerObj.regions

const setthevalueofregion = (SelectedReg,Region) => {
  // console.log("  selectedIndex  ",  SelectedReg.selectedIndex - 1)
  // console.log("  SelectedReg  ",  SelectedReg)
  // console.log("  document  ",  Region)
  // console.log("  documentref  ", Region[SelectedReg.selectedIndex - 1]  )
  setregionvaluecity(Region[SelectedReg.selectedIndex - 1].regionName)
  props.setregionValefun(Region[SelectedReg.selectedIndex - 1])
  // console.log(Region[SelectedReg.selectedIndex - 1])
  setregionvalue(Region[SelectedReg.selectedIndex - 1])
  setLSregionvalue(Region[SelectedReg.selectedIndex - 1].regionId)
  localStorage.setItem('Reagon',JSON.stringify(Region[SelectedReg.selectedIndex - 1]))
  props.setdelvVal(Region[SelectedReg.selectedIndex - 1].deliveryFees)
}

const setthevalueofsaleman = (SelectedReg,salemanlst) => {
  props.setsaleIdP(salemanlst[SelectedReg.selectedIndex - 1].firstName + ' ' + salemanlst[SelectedReg.selectedIndex - 1].lastName)
  props.setsaleIdmanP(salemanlst[SelectedReg.selectedIndex - 1].salesmanID)
}
//////////////////////handle button of time delivery///////////////////
            //  const [TimeDelivery, setTimedelivery] = useState(0);
            //  const[DeliveryStatus,setdeliverystatus]=useState(0);
            //  // btn of 2 hours
            // const handlebchangetobtn2= event =>{
            //   event.preventDefault();
            //   setdeliverystatus(1);
            //   tooglebutton('delived');
            //   }
            //   // btn of 3 hours
            //   const handlebchangetobtn3= event =>{
            //     event.preventDefault();

            //     setTimedelivery(0);
            //     tooglebuttonofdelivery('now');
                

            //     }
            //     // btn of 4 hours
            //     const handlebchangetobtn4= event =>{
            //       event.preventDefault();
            //       setTimedelivery(1);
            //       tooglebuttonofdelivery('after2');

            //       }
                   ///// btn  oof now 
                  // const handlebchangetobtnnow= event =>{
                  //   event.preventDefault();
                  //   setdeliverystatus(0);
                  //   tooglebutton('hand');
                  //   }
                    //btn  tommorow morning
                    // const handlebchangebtntomAm= event =>{
                    //   event.preventDefault();
                    //   setTimedelivery(2);
                    //   tooglebuttonofdelivery('tomM');

                    //   }
                      // btn tommorow evening 
                      // const handlebchangebtntommidAm= event =>{
                      //   event.preventDefault();
                      //   setTimedelivery(3);
                      //   tooglebuttonofdelivery('tomE');

                      //   }
                        //btn tommorow night
                        // const handlebchangebtntompm= event =>{
                        //   event.preventDefault();
                        //   setTimedelivery(4);
                        //   tooglebuttonofdelivery('tomN');

                        //   }

  
/////////////////////upload voice//////////////
const [blobList, setBlobList] = useState([]);
  // current file to upload into container
const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36).split());
  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);
    // *** UPLOAD TO AZURE STORAGE ***
    const blobsInContainer = await uploadFileToBlob(props.flieup);
    // prepare UI for results
    setBlobList(blobsInContainer);
    // reset state/form
    setUploading(false);
    setInputKey(Math.random().toString(36));
     }
     ////////////////////remember me //////////////////////////////
// to use ref in remember me checked
 const ref = useRef(null)

// function remember me 
function lsRememberMe(){
  if (ref.current.checked){
    
    localStorage.setItem('email',email)
    localStorage.setItem('name',name)
    localStorage.setItem('phone',phone)
    localStorage.setItem('address',CurrentLoc) 
    localStorage.setItem('Reagon',JSON.stringify(regionvalue) ) 

    localStorage.setItem('rememberMe',ref.current.checked) 
   // localStorage.setItem('rememberMe',ref) 
     
  }
  else{
    localStorage.clear()
  }
  
}
const scrollBlocked = useRef();
const safeDocument = typeof document !== 'undefined' ? document : {};
const html = safeDocument.documentElement;
const { body } = safeDocument;
function showpopup(){
  setpopupcontact(true)
  document.body.classList.add('body-scroll-lock');
  if (!body || !body.style || scrollBlocked.current) return;

  const scrollBarWidth = window.innerWidth - html.clientWidth;
  const bodyPaddingRight =
    parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

  /**
   * 1. Fixes a bug in iOS and desktop Safari whereby setting
   *    `overflow: hidden` on the html/body does not prevent scrolling.
   * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
   *    scroll if an `overflow-x` style is also applied to the body.
   */
  html.style.position = 'relative'; /* [1] */
  html.style.overflow = 'hidden'; /* [2] */
  body.style.position = 'relative'; /* [1] */
  body.style.overflow = 'hidden'; /* [2] */
  // body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

  scrollBlocked.current = true;
}
///////////////////////////////////////// to put data when reload page ///////////////
useEffect(() => {
  showpopup()
  setphone(localStorage.getItem('phone'));
  setname(localStorage.getItem('name'));
setCurrentLoc(localStorage.getItem('address'));
setemail(localStorage.getItem('email'));
var regon = localStorage.getItem('Reagon');
// console.log("Reagon2",regon);
// console.log("json Reagon2",JSON.parse(regon));
if(regon != null){
try
{
  var jsregon = JSON.parse(regon)
setregionvalue(jsregon);
setLSregionvalue(jsregon.regionId)
props.setdelvVal(jsregon.deliveryFees)
}
catch{}
}
if(localStorage.getItem('phone')!=''){
  ref.current.checked = true;

}
}, []);

const importFile= async (e) => {
  if(submitting == false){
    setsubmitting(true);
  const formData = new FormData();
  formData.append("order", JSON.stringify(dataform));
  formData.append("file", dataform.consumer.record);
  try {
    const res = await axios.post("https://tijarymagazineapis.azurewebsites.net/SubmitOrder", formData)
    .then((resp) =>{
      seteleprint(true)
      Swal.fire({
        title: 'تم الأرسال',
        icon: 'success',
        timer: 2000,
        confirmButtonText: 'تم'
    }).then(() => {
      var printContents = document.getElementById("divprint").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      localStorage.removeItem("saleid")

      window.location.reload()
    })
     })
   
  } catch (ex) {
  }
  setsubmitting(false);
}
};
const MARGIN = 2;
const createScreenshotPDF = () => {
  // get the content element
  const content = document.getElementById('divprint');



  // get content size
  const width = 250 ;
  const height = content.clientHeight;

  // call html2canvas to save the screenshot to a pdf file
  html2canvas(content, {
    scrollX: 0, // scroll to X origin
    scrollY: 5, // scroll to Y origin
    scale: 5, // 5 is quality scale
  }).then(function (canvas) {
    // convert the canvas conent to a Data URI/URL
    var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
    var doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px', // set pixel as unit
      format: [width + MARGIN * 1, height + MARGIN * 1],
    });

    // add canvas as an image
    doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

    // save the content to screenshot.pdf
    doc.save('الطلبيه.pdf');
    localStorage.removeItem("saleid")
    window.location.reload()
  });
};
const importFilemobile= async (e) => {
  if(submitting == false){
  setsubmitting(true);
  const formData = new FormData();
  formData.append("order", JSON.stringify(dataform));
  formData.append("file", dataform.consumer.record);
  try {
    const res = await axios.post("https://tijarymagazineapis.azurewebsites.net/SubmitOrder", formData)
    .then((resp) =>{
      seteleprint(true)
      Swal.fire({
        title: 'تم الأرسال',
        icon: 'success',
        timer: 2000,
        confirmButtonText: 'تم'
    }).then(() => {
      createScreenshotPDF()

    })
     })
   
  } catch (ex) {
    console.log(ex)
  }
  setsubmitting(false);
}
};



function tooglebutton(DeliveryStatus){
  const x=document.getElementById('delived')
  const y=document.getElementById('hand')
  if(DeliveryStatus == 'delived'){
    y.style.background='red'
    x.style.background='#696969'

  }
  else{
    y.style.background='#696969'
    x.style.background='red'
  }
}
function tooglebuttonofdelivery(timeofdelivey){
  const x=document.getElementById('now')
  const y=document.getElementById('after2')
  const a=document.getElementById('tomM')
  const z = document.getElementById('tomE')
  const b=document.getElementById('tomN')
  if(timeofdelivey == 'now'){
    y.style.background='#696969'
    a.style.background='#696969'
    z.style.background='#696969'
    b.style.background='#696969'
    x.style.background='red'

  }
  else if(timeofdelivey == 'after2'){
    y.style.background='red'
    a.style.background='#696969'
    z.style.background='#696969'
    b.style.background='#696969'
    x.style.background='#696969'


  
  }
  else if(timeofdelivey == 'tomM'){
    a.style.background='red'
    z.style.background='#696969'
    b.style.background='#696969'
    x.style.background='#696969'
    y.style.background='#696969'

  }
  else if(timeofdelivey == 'tomE'){
    a.style.background='#696969'
    z.style.background='red'
    b.style.background='#696969'
    x.style.background='#696969'
    y.style.background='#696969'

  }
  else if(timeofdelivey == 'tomN'){
    a.style.background='#696969'
    z.style.background='#696969'
    b.style.background='red'
    x.style.background='#696969'
    y.style.background='#696969'

  }

}
function paymentmethod(Id){
  const AllPayBtn=document.getElementsByClassName('paymentbutton')
  const SelectedpayBtn =document.getElementById('paymentmethod'+Id)
  for (var i = 0; i < AllPayBtn.length; i++) {
    AllPayBtn[i].style.backgroundColor="white";
}
  SelectedpayBtn.style.background='#696969'
}
const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
const [eleprint,seteleprint]=useState(false)
var LS = localStorage.getItem('objtotal');


if(LS != null)
   console.log("props.OrderItemLst", JSON.parse(LS));
   
const showprint=event=>{
event.preventDefault()

props.handleprint(true)
  console.log("props.handleprint",props.handleprint)


}
const Print = () =>{     
  let printContents = document.getElementById('printablediv').innerHTML;
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
 document.body.innerHTML = originalContents; 
}




return(
  <div className='container-fluid fontmaaray mt-3'>
  {eleprint==false? 
     <div className='row justify-content-center' id='maindiv'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <FaRegUser  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center mt-1'>بيانات الشخصية</h1> 
         

         
            </div>
    </div>
    <form className='container-fluid' /*</div>onSubmit={handleSubmit}*/>
    <div className='row'> 
    <div>
      
  {/*  <div class="form-group">
    <input
          id="first_name"
          name="first_name"
          type="text"
          onChange={event => setFirstName(event.target.value)}
          value={firstName}
        />
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />

</div>*/}

         <div className="form-group">
    <input type="text" className="form-control mt-3 " style={{'font-size': 'x-large'}}  id='phone'   name='phone' value={phone} onChange={handleChangephone}   placeholder="رقم موبيل " ></input>
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 "style={{'font-size': 'x-large'}} placeholder="الاسم" id='name' value={name}  name='name' onChange={handleChangename}></input>
    <input type="text" className=" inputshow" value={comment} name='comment'  onChange={handleChangecomment}></input>
  </div>
  <div className="form-group ">
  <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
          <div className='col-12'>
 <div className='d-flex mt-2'> 
 
 <div className='col-lg-10 col-10' style={{marginRight:"1%"}}>
 <div className='d-flex form-group' > 
<div className='col-12'  >

<select style={{height:"46px",border:"1px solid  #ced4da",borderRadius:"5px",width:"99%",outline:"none"
                ,marginLeft:"5px",textAlignLast:"right",direction:"rtl"  }} 
onChange={(event) => setthevalueofregion(event.target,optionList)} value={LSregionvalue}>
<option value="-1" disabled selected>من فضلك اختار المنطقه</option>
{optionList.map((reg, index) =>

      <option id='theregionvalue' key={index} value={reg.regionId}  >
       {reg.regionName}
      </option>
     )}
 </select>
</div>


</div>



<div className='col-12 mt-2'>
<input
        style={{ fontSiz:"14px",height:"46px",width:"99%",marginRight:0}}
   
        onChange={evt => InputChange(evt.target.value)} 
        
        className="mt-2  form-control text-area-width " type='text'  placeholder="العنوان" 
       value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>
</div>

 </div>
   
       <button if onClick={getCurrentLocation} disabled={loading} className='btn btn-warning col-1 btnwidth' style={{marginRight:0}}>
         <div className='text-white'>
         <IoLocation className='fs-5 text-center '></IoLocation>
         <p style={{ justifyContent:"center",display:"flex"}}>مكاني</p>
         </div> 
       </button>
       </div>
          </div>
        

      )}
    </CurrentLocation>
  
  {/* <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
           <div className='row mt-2'> 
  
         <input
         onChangoe={evt => InputChange(evt.target.value) } 
         className="col-lg-11   form-control text-area-width" style={{'width': '80%','margin-right': '1%' ,'font-size': 'x-large'}} type='text'  placeholder="العنوان" 
        value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>

        <button if onClick={getCurrentLocation}  disabled={loading} style={{'width':'17%','margin-right':'1%','margin-top':'0px' }} className='btn btn-warning col-sm-1 btnwidth '>
          <div className='text-white mt-1'>
          <IoLocation className='text-center fs-2 ' style={{'width':'100%'}}></IoLocation>
           <p className=' fs-4'>مكاني</p>
          </div> 
        </button>
        </div>
        

      )}
    </CurrentLocation> */}

 
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 " style={{'font-size': 'x-large'}} placeholder="اسم المحل" onChange={handleChangeemail} value={email} id='email' name='email'></input>
  </div>
  <div className='row mt-3'>
  
 
  {isDesktopOrLaptop &&
  <div className='row'>
    <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%','margin-right': '-120px'}} ref={ref} id="rememberMe"></input>
  </div>
   <div className=' col-6'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success' style={{'margin-right': '-350px'}}  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div>
   }
   {isTabletOrMobile &&
   <div className='row'>
     <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%'}} ref={ref} id="rememberMe"></input>
  </div>
    <div className='col-9'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success'  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div> }
   

  </div>

  {/* <div className='row  justify-content-center mt-2 '>
  <input value={TimeDelivery}  onChange={handleChangedate} id='date' name='date' className='hidedeliveredinput'></input>
    <button style={{'width':'45%' ,'height':'10%','margin-left':'4%','background':'red','font-weight': 'bold'}} className=' btn text-white ' id='delived' type="button" onClick={handlebchangetobtnnow} >توصيل </button>
    <button  style={{'width':'45%','margin-left':'2%','background':'#696969'}} onClick={handlebchangetobtn2} id='hand' className='btn text-white' type="button" value='1'>إستلام </button>

    </div> */}
  <hr></hr>
{/* 
  <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <GiDeliveryDrone  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>ميعاد التوصيل  </h1> 
         

         
            </div>
    </div>
  
  <div className='row mt-3'>



<div id='displaybtn' className='container-fluid'>

    <div className='row justify-content-center mt-2 '>
  <button style={{'width':'94%','margin-left':'2%','background':'red','font-weight': 'bold' }} className='btn  btn-white text-white' id='now' type="button" onClick={handlebchangetobtn3}>الأن</button>
</div>
<div className='row justify-content-center  mt-2' >
<button style={{'width':'22%','margin-left':'2%','background':'#696969'}}  className='btn btn-white text-white'  id='after2' type="button" onClick={handlebchangetobtn4}>بعد 2 ساعه  </button>

    <button style={{'width':'23%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white'id='tomM' type="button" onClick={handlebchangebtntomAm} >غداً&nbsp;&nbsp; ص . </button>
  <button  style={{'width':'22%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white' id='tomE' type="button" onClick={handlebchangebtntommidAm}>غداً&nbsp;&nbsp;ظ. </button>
  <button style={{'width':'22%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white' id='tomN' type="button" onClick={handlebchangebtntompm}>غداً&nbsp; &nbsp;م. </button>
</div>
</div>

</div> */}
{/* <hr className='mt-4' ></hr> */}

<div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <MdPayment  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>طريقه السداد</h1> 
         

         
            </div>
    </div>


<div className="row mt-4">
  
   

    {props.headerObj.payments.map((Slider, index) => (
          <div className='col-4'>

  {/*<input style={{'display':'none'}} type="radio" id="louie" name={'visa'} value={Slider.id} onChange={handleChangepaymentMethod} />*/}
  <label for={Slider.paymentName}> 
  <button className='paymentbutton' style={{'border':'none', 'background':props.headerObj.backGround,'color':'white'}} name={'visa'} id={'paymentmethod' + Slider.id} onClick={(e) =>{paymentmethod(Slider.id)(e.preventDefault())}}>

  <img className='widthofmezza' src={Slider.paymentIcon} value={Slider.id} name={'visa'} onClick={() => handleChangepaymentMethod(Slider.id)} >
</img>
</button>
</label>
</div>

    ))}
    

</div>
<div className='row'>

<div className='col-lg-12 col-12 sendindesktop' >
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheightdes " onClick={handleItemSubmit}><FiSend className='fs-1 mx-4 mt-2'>
  
  </FiSend>
  ارسل  </button>
</div>
<div className='col-lg-12 col-12 sendindmobile'>
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheightmob sendindmobile" onClick={handleItemSubmitmobile}><FiSend className='fs-1 mx-4 mt-2'>
  
  </FiSend>
  ارسل  </button>
</div>


 
 
    </div>
  </div>
  </div>
 
</form>

</div>

</div>:  


<div className='container-fluid mt-3' id='divprint'>
    <div className='row justify-content-center'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
    <div className='container-fluid bord'>

        <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
        <h1 className='col-1'>  <BsCart2  className=' text-white fs-1 mt-2' />  </h1>   
            <h1 className='text-light col-10 text-center fontmaaray mt-1'>الطلبيه</h1>
            </div>
            {/*table of order item */}  


          <div className='row  fontmaaray ' >
<div className='row  justify-content-center fontmaaray'>
<h6  className='col-lg-6  col-6  fontmaaray'  style={{'color':props.headerObj.fontcolor}}>صنف</h6>
{/* <h6  className='col-lg-3  col-2  fontmaaray'  style={{'color':props.headerObj.fontcolor}}>سعر</h6>  */}

<h6  className='col-lg-3 col-3 p-0 fontmaaray' style={{'color':props.headerObj.fontcolor}}>سعر</h6>
<h6  className='col-lg-3 col-3 p-0  fontmaaray' style={{'color':props.headerObj.fontcolor}}> اجمالي </h6>

</div>
</div>      

 
    {/*maping from list to show order details [name, quntity,total discount,total ] */}
{props.OrderItemLst.map(order => (
  <div className='row justify-content-center  mt-2 fontmaaray' id='myTablephone' style={{borderBottom:"1px solid lightgray"}}>
  
  <div className='col-12 mt-2'> 
  <div className='row'>
  <div className='col-2 p-0'>
  {/* style={{'color':props.headerObj.fontcolor}} */}
<div className='row'>
<h6 className='fontmaaray ' style={{fontWeight:"bold",color:"#bb0202"}}  >[{parseInt(order.itemQty)}]</h6>

</div>
<div className='row'>
<p>{order.eqvName}</p>
</div>
</div>
<div className='col-9 p-0'>
<div className='row'>
<h6 className='fontmaaray '  style={{'color':props.headerObj.fontcolor,textAlign:"right"}}>{order.itemName}</h6>

</div>


</div>
<div className='row'>
  <div className='col-3'>

  </div>
{order.itemDiscountAmount==0?<div className='col-6' style={{textAlign:"left"}}>
<p>{parseInt(order.itemSalesPrice).toFixed(2)}
</p>
</div>
:
<div className='col-6'>

<div className='row' style={{textAlign:"left"}}>
<div className='col-8'>
<del>{parseInt(order.itemSalesPrice).toFixed(2)}
</del>
</div>
<div className='col-4 p-0'>
<p style={{textAlign:"right"}}>{parseInt(order.itemDiscountAmount).toFixed(2)}</p>

</div>
</div>


</div>}
<div className='col-2 p-0'>
  <div className='row'>
<h6 className=' fontmaaray '  style={{color:"#bb0202",fontWeight:"bold"}}>{order.total.toLocaleString()}</h6>
</div>

</div>


</div>


  </div>
  
  </div>

</div>


))}
{/*total of order  */}

  

    
</div>
{LS ? 
  <div className='container-fluid mt-2'>
  <div className='row' >
    <div className='row'>
    <h6 className='col-lg-4 fs-4 col-5 fontmaaray' style={{'text-align':'right' ,'white-space':'nowrap','color':props.headerObj.fontcolor}}>  عدد الأصناف </h6> 
    <h6 className='col-lg-2 fs-4 col-3 fontmaaray '  style={{'text-align':'left' , 'color':props.headerObj.fontcolor}}>({JSON.parse(LS).totalqut.toLocaleString()})</h6>
    <hr className='mt-3 col-lg-5 col-3 fontmaaray' style={{'margin-right': '6%'}}></hr>
    </div>
    <div className='row '>
      <h6 className='col-1 fs-4 fontmaaray' style={{'text-align':'right','color':props.headerObj.fontcolor}}> الوفر</h6>
      <hr className='mt-3 col-10 fontmaaray' style={{'width':'60%' ,'color':'white'}}></hr>
      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}> {parseInt(JSON.parse(LS).totaldiscount ).toFixed(2)}</h6>
    </div>
    <div className='row'>
      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}> النقل</h6>
      <hr className='mt-3 col-10 fontmaaray' style={{'width':'60%' ,'color':'white'}}></hr>
      <h6 className='col-1 fs-4 fontmaaray' style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}>{parseInt(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>
    </div>
    {LS.Nettotal==0?
    
     <div className='row'>
      <h6 className='col-2 fs-4 fontmaaray' style={{'text-align':'right' ,'white-space':'nowrap','color':props.headerObj.fontcolor}}>صافي الإجمالي </h6>
      <hr className='mt-3 col-7 fontmaaray' style={{ 'color':'white','width':'51.5%'}}></hr>

      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}>{(JSON.parse(LS).Nettotal+props.headerObj.defaultDeliveryPrice).toLocaleString(2) }</h6>

    </div>
    
    
    
    
    :    <div className='row'>
      <h6 className='col-2 fs-4 fontmaaray' style={{'text-align':'right' ,'white-space':'nowrap','color':props.headerObj.fontcolor}}>صافي الإجمالي </h6>
      <hr className='mt-3 col-7 fontmaaray' style={{ 'color':'white','width':'51.5%'}}></hr>

      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}>{(JSON.parse(LS).Nettotal+props.headerObj.defaultDeliveryPrice).toLocaleString(2) }</h6>

    </div>}

  </div>
  

</div>
:"" }


</div>


</div>
<div className='row justify-content-center'>
<div className='col-4'>
<p>{props.saleIdVP}</p>
</div>
<div className='col-1'>
<AiOutlineUser style={{fontSize:"22px",marginTop:"28%"}}></AiOutlineUser>

</div>

</div>
</div>



 }


 {popupcontact==true?
  <div className="modal-abouttijary  d-flex justify-content-center  align-items-center" >
    <div className="modal-dialog modal-dialog-centered" >
      <div className="modal-content  ">
        <div className="modal-header" style={{"direction": "rtl"}}>
        <div style={{"flex": "1"}}>
        <div className='row justify-content-center'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <FaRegUser  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center mt-1'>بيانات الشخصية</h1> 
         

         
            </div>
    </div>
    <form className='container-fluid'>
    <div className='row'> 
    <div>
      
         <div className="form-group">
    <input type="text" className="form-control mt-3 " style={{'font-size': 'x-large'}}  id='phone'   name='phone' value={phone} onChange={handleChangephone}   placeholder="رقم موبيل " ></input>
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 "style={{'font-size': 'x-large'}} placeholder="الاسم" id='name' value={name}  name='name' onChange={handleChangename}></input>
    <input type="text" className=" inputshow" value={comment} name='comment'  onChange={handleChangecomment}></input>
  </div>
  <div className="form-group ">
  <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
          <div className='col-12'>
 <div className='d-flex mt-2'> 
 
 <div className='col-lg-10 col-10' style={{marginRight:"1%"}}>
 <div className='d-flex form-group' > 
<div className='col-12'  >

<select style={{height:"46px",border:"1px solid  #ced4da",borderRadius:"5px",width:"99%",outline:"none"
                ,marginLeft:"5px",textAlignLast:"right",direction:"rtl"  }} 
onChange={(event) => setthevalueofregion(event.target,optionList)} value={LSregionvalue}>
<option value="-1" disabled selected>من فضلك اختار المنطقه</option>
{optionList.map((reg, index) =>

      <option id='theregionvalue' key={index} value={reg.regionId}  >
       {reg.regionName}
      </option>
     )}
 </select>
</div>


</div>



<div className='col-12 mt-2'>
<input
        style={{ fontSiz:"14px",height:"46px",width:"99%",marginRight:0}}
   
        onChange={evt => InputChange(evt.target.value)} 
        
        className="mt-2  form-control text-area-width " type='text'  placeholder="العنوان" 
       value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>
</div>

 </div>
   
       <button if onClick={getCurrentLocation} disabled={loading} className='btn btn-warning col-1 btnwidth' style={{marginRight:0}}>
         <div className='text-white'>
         <IoLocation className='fs-5 text-center '></IoLocation>
         <p style={{ justifyContent:"center",display:"flex"}}>مكاني</p>
         </div> 
       </button>
       </div>
          </div>
        

      )}
    </CurrentLocation>
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 " style={{'font-size': 'x-large'}} placeholder="اسم المحل" onChange={handleChangeemail} value={email} id='email' name='email'></input>
  </div>
  {/* <div className='row justify-content-center'>
<div className='col-lg-3' onClick={props.showsalemanpopup}>
<div className='row' style={{color:"red"}}>
<div className='col-1 p-0'>
<AiOutlineUser style={{fontSize:"22px",marginTop:"28%"}}></AiOutlineUser>

</div>
{props.saleIdVP==null|| props.saleIdVP==undefined ? <div className='col-11 p-0'>
<p className='fs-4 fontmaaray '    style={{textAlign:"right",cursor:"pointer"}}> اختر ممثل مبيعات </p>

</div>:
<div className='col-11 p-0'>
<p className='fs-4 fontmaaray' style={{textAlign:"right",cursor:"pointer"}}>{props.saleIdVP} </p>

</div>}

</div>
</div>
</div> */}
<div className="form-group">

<select style={{height:"46px",border:"1px solid  #ced4da",borderRadius:"5px",width:"100%",outline:"none"
                ,marginLeft:"5px",textAlignLast:"right",direction:"rtl",marginTop:"2%"  }} 
onChange={(event) => setthevalueofsaleman(event.target,props.headerObj.salesmen)} >
<option value="-1" disabled selected>اختر ممثل مبيعات</option>
{props.headerObj.salesmen.map((reg, index) =>

      <option id='thesalemanvalue' key={index} value={reg.salesmanID}  >
       {reg.firstName + " " + reg.lastName}
      </option>
     )}
 </select>
</div>

  </div>
  </div>
 
</form>

</div>

</div>
    
      <div className='row'>

<div className='col-lg-12 col-12 sendindesktop' >
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheightdes " onClick={closepopupcontact}>
  تم  </button>
</div>
<div className='col-lg-12 col-12 sendindmobile'>
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheightmob sendindmobile" onClick={closepopupcontact}>
  تم  </button>
</div>


 
 
    </div>

    
      </div>
  </div>
  </div>
  </div>
  </div>
:""}


</div>

)
}

export default Formuser ;