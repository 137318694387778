import 'bootstrap/dist/css/bootstrap.css';
import RecordView from '../recorder/recorder'
import './nomagazine.css';
import 'bootstrap/dist/css/bootstrap.css';
import React,{useState,useEffect} from 'react';
import '../form-user/info.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useJsApiLoader } from '@react-google-maps/api';
import { IoLocation } from "react-icons/io5";
import CurrentLocation from './CurrentLocation';
import {FaRegUser} from "react-icons/fa";
import uploadFileToBlob, { isStorageConfigured } from '../recorder/azureBlob';
import {useRef} from 'react';
import { FiSend } from "react-icons/fi";
import axios from "axios";
import swal from 'sweetalert';
import { MdOutlineDeliveryDining ,MdPayment } from "react-icons/md";
import { useMediaQuery } from 'react-responsive';
import Swal from "sweetalert2";
import { FacebookShareButton } from 'react-share';
import { TwitterShareButton } from 'react-share';
import { WhatsappShareButton } from 'react-share';
import { AiFillCopy } from "react-icons/ai";
import EmailIcon from '@mui/icons-material/Email';
import bedab from '../assets/Bedab-Logo-Blue.png'
import { WhatsApp } from '@mui/icons-material';
import tijary from '../assets/tijarylog.jpeg'

import {
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import { AiFillPlayCircle } from "react-icons/ai";
import { HiShare } from "react-icons/hi";

import $ from 'jquery';



const onError = (type, status) => console.log(type, status)

 function Nomagazine(props){
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const[showagain,setshowagain]=useState(false)
  const [url,seturl]=useState(window.location.href)

  const storageConfigured = isStorageConfigured();
  //to get current location 
  const [CurrentLoc, setCurrentLoc] = useState('');
  const handleResults = (results) => {
    setCurrentLoc(results.filter((item) => {
      return (item.types.indexOf('street_address') >= 0);
    })[0].formatted_address)
   }
  // to put current location in input 
  const InputChange= (Value) => setCurrentLoc(Value);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyASbQBHuvWt93PcUlu1F2HAjmrboNmNS2Q"
  })
  //to put input value 
  
  // object that has details of order and details of user form 
  const dataform={
   consumer:{ 
    phone:'',
    name:'',
    address:'',
    email:'',
    paymentMethod:'',
    record:"",
    deliveryOrder:1
   },
   orderItemLst:[{
    detOrder:props.detOrder
  
   }],
   merchantMagazineId:''
   
  //  dateofdeliverd:'',
    // comment:''
  };
  // blob of record 
  // const[mediaBlobUrl,setmediaBlobUrl]=useState('')
  // to declare value of input form 
  const[phone,setphone]=useState()
  const[name,setname]=useState()
  const[email,setemail]=useState()
  const[dateofdeliverd,setdatedeliv]=useState()
  const[paymentMethod,setpaymentMethod]=useState()
  //to set value of input 
  const handleChangephone = event => {
    setphone (event.target.value);
  
  };
  
  console.log(props.comment)
  const handleChangename = event => {
    setname (event.target.value);
  
  };
  
  const handleChangeemail = event => {
    setemail (event.target.value);  
  };
  
  const handleChangepaymentMethod = event => {
    setpaymentMethod (event.target.value);
  };
  
  const handleChangedate = event =>{
    setdatedeliv(event.target.value)
  };
  
  const [comment, setcomment] = useState('');
  const handleChangecomment = event => {
    
    setcomment(event.target.value)
    console.log('value is:', event.target.value);
  };
  


  //function of submit (set value of form and audio and order )
  const handleItemSubmit = (event) => {
    event.preventDefault();
    console.log(props.comment)
    dataform.consumer.phone=phone
    dataform.consumer.name=name
    dataform.consumer.email=email  
    dataform.consumer.address=CurrentLoc
    dataform.consumer.comment=props.CommentNomagazine
    dataform.merchantMagazineId=props.merchantMagazineId
    dataform.consumer.record=props.Recfile
    dataform.consumer.message=message
    if(dataform.consumer.address=='' || dataform.consumer.address==null)
  {
    swal({
     
      text: "يجب أدخال العنوان",
      buttons: true,
      dangerMode: true,
      
  
    })
  }  

  else if(!dataform.consumer.phone.match(/^\d+$/))
  {
    
    console.log("type",typeof(dataform.consumer.phone))
  
    Swal.fire("تاكد من التليفون ")
  
  
  }

  else if(dataform.consumer.email=='' || dataform.consumer.email==null)
  {
    swal({
     
      text: "يجب أدخال الأيميل",
      buttons: true,
      dangerMode: true,
      
  
    })
  }
  else if(dataform.consumer.name=='' || dataform.consumer.name==null)
  {
    swal({
     
      text: "يجب أدخال الأسم",
      buttons: true,
      dangerMode: true,
      
  
    })
  }

  else if(dataform.consumer.phone=='' || dataform.consumer.phone==null )
  {
    swal({
     
      text: "يجب أدخال التليفون ",
      buttons: true,
      dangerMode: true,
      
  
    })
    
  }
  else if(dataform.consumer.comment== undefined && dataform.consumer.record==undefined){

    Swal.fire("من فضلك ارسل رساله صوتيه او ملاحظات علي الاقل   ")
  
  
  }
 
  else{
  
    onFileUpload()
    lsRememberMe()
  //  createPost()
    importFile()
  }
  
  
  }
  const importFile= async (e) => {
    const formData = new FormData();
    formData.append("order", JSON.stringify(dataform));
    formData.append("file", dataform.consumer.record);
    try {
      const res = await axios.post("https://tijarymagazineapis.azurewebsites.net/SubmitOrderNoMagazine", formData)
      .then((resp) =>{   
        Swal.fire({
          title: 'تم الأرسال',
          icon: 'success',
          timer: 2000,
          confirmButtonText: 'تم'
      })
             window.location.reload()});

  
    } catch (ex) {
      console.log(ex);
    }
  };
  //////////////////////handle button of time delivery///////////////////
               const [message, setMessage] = useState('');
               // btn of 2 hours
              const handlebchangetobtn2= event =>{
                event.preventDefault();
                    setMessage(1);
                    console.log(message)
                }
                // btn of 3 hours
                const handlebchangetobtn3= event =>{
                  event.preventDefault();
                      setMessage(2);
                  }
                  // btn of 4 hours
                  const handlebchangetobtn4= event =>{
                    event.preventDefault();
                        setMessage(3);
                    }
                     ///// btn  oof now 
                    const handlebchangetobtnnow= event =>{
                      event.preventDefault();
                          setMessage(4);
                      }
                      //btn  tommorow morning
                      const handlebchangebtntomAm= event =>{
                        event.preventDefault();
                            setMessage(5);
                        }
                        // btn tommorow evening 
                        const handlebchangebtntommidAm= event =>{
                          event.preventDefault();
                              setMessage(6);
                          }
                          //btn tommorow night
                          const handlebchangebtntompm= event =>{
                            event.preventDefault();
                                setMessage(7);
                            }
  
    
  /////////////////////upload voice//////////////
  const [blobList, setBlobList] = useState([]);
    // current file to upload into container
  const [uploading, setUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36).split());
    const onFileUpload = async () => {
      // prepare UI
      setUploading(true);
      // *** UPLOAD TO AZURE STORAGE ***
      const blobsInContainer = await uploadFileToBlob(props.flieup);
      // prepare UI for results
      setBlobList(blobsInContainer);
      // reset state/form
      setUploading(false);
      setInputKey(Math.random().toString(36));
       }
       ////////////////////remember me //////////////////////////////
  // to use ref in remember me checked
   const ref = useRef(null)
  
  // function remember me 
  function lsRememberMe(){
    if (ref.current.checked){
      
      localStorage.setItem('email',email)
      localStorage.setItem('name',name)
      localStorage.setItem('phone',phone)
      localStorage.setItem('address',CurrentLoc) 
      localStorage.setItem('rememberMe',ref.current.checked) 
     // localStorage.setItem('rememberMe',ref) 
       
    }
    else{
      localStorage.clear()
    }
    
  }
  const merchantMagazineId = 1

     
  const fetchIdmagazine = (merchantId) => {
    props.setloaddata(true)
    return axios.get("https://tijarymagazineapis.azurewebsites.net/GetLastMagazineIDwithMagazineMerhanctID/"+merchantId)
          .then((response) => {
           fetchHeader(response.data.magazineID);
            });
  }
  const [header, setHeader] = useState();
  
  const fetchHeader = (magazineID) => {
    props.setloaddata(true)
    return axios.get('https://tijarymagazineapis.azurewebsites.net/api/MagazineHead/GetDefaultMerchantMagazine/'+magazineID)
          .then((response) => {
            setHeader(response.data.defaultHeaders);
            console.log("response.data.defaultHeaders",response.data.defaultHeaders)
        // props.setloaddata(false)
          })
         
  }
  ///////////////////////////////////////// to put data when reload page ///////////////
  useEffect(() => {
    setphone(localStorage.getItem('phone'));
    setname(localStorage.getItem('name'));
  setCurrentLoc(localStorage.getItem('address'));
  setemail(localStorage.getItem('email'));
  if(localStorage.getItem('phone')!=''){
    ref.current.checked = true;
  }
  fetchIdmagazine(merchantMagazineId);
  }, []);
  
  function createPost() {
  
    const options = {
      method: 'post',
      url: 'http://172.16.0.249:5555/SubmitOrder',
      data: dataform,
      transformResponse: [(data) => {
        console.log(data);
        return data;
      }]
    };
    // send the request
    axios(options);
  }
  const showprint=event=>{
    event.preventDefault()
    
    props.handleprint(true)
      console.log("props.handleprint",props.handleprint)
    
    
    }
    function paymentmethod(Id){
      const AllPayBtn=document.getElementsByClassName('paymentbutton')
      const SelectedpayBtn =document.getElementById('paymentmethod'+Id)
      for (var i = 0; i < AllPayBtn.length; i++) {
        AllPayBtn[i].style.backgroundColor="white";
    }
      SelectedpayBtn.style.background='#696969'
    }
    const videoload = event =>{
      Swal.fire({
        // icon: 'info',
        html:
          '<iframe class="iframwidth" height="300" src="https://www.youtube.com/embed/5KtYqJwpgRg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
           +'<label class="fontmaaray">عدم اظهار الفيديو مره اخري</label>'
           +'<input type="checkbox" id="cheackinvideo" >',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          'موافق',
        confirmButtonAriaLabel: 'Thumbs up, great!',
    
      }).then((result) => {
    const x=document.getElementById("cheackinvideo").checked
    const Elecheck=document.getElementById("cheackinvideo")
        if (result.isConfirmed && x === true) {
          setshowagain(true)
          localStorage.setItem('showagain', true)
          Elecheck.setAttribute("checked","")
          
    
        }
       
      else{
        localStorage.setItem('showagain', false)
    
    
      }
      
      })
    
      var cheackinvideo = document.getElementById("cheackinvideo");
      var showagain = localStorage.getItem('showagain')
    
      if(showagain == "true")
         cheackinvideo.setAttribute("checked",showagain);
      else
         cheackinvideo.removeAttribute("checked");
         localStorage.setItem('showagain', false)
    
    }
    const openModalofshare = () => {
      $('.modalshare').removeClass("d-none");
      $('.modalshare').addClass("d-block");
      $('body').addClass("disable-scroll");
    };
    
    const closeModalofshare = () => {
      $('.modalshare').removeClass("d-block");
      $('.modalshare').addClass("d-none");
      $('body').removeClass("disable-scroll");
    };
    const openModal = () => {
      $('.modal-yasser').removeClass("d-none");
      $('.modal-yasser').addClass("d-block");
      $('body').addClass("disable-scroll");
    };
    
    const closeModal = () => {
      $('.modal-yasser').removeClass("d-block");
      $('.modal-yasser').addClass("d-none");
      $('body').removeClass("disable-scroll");
    };
    const[Recfile,setRecfile]=useState(props.Recfile)
    const[CommentNomagazine,setCommentNomagazine]=useState(props.CommentNomagazine)

    return (
        <div>
    {header && 
     <div >         
     <div > 
     <div className="row justify-content-center">
      
      <div className="col-3 mt-2">
        
      <img src={header.headerLogo} style={{maxWidth: "90%"}} class="logo-imagesNomazine"></img>
      </div>
      <div className="col-9 mt-2">
        <div className="row justify-content-center headinmobile" >
      <h3 className="text-header card-title magazine-title " style={{"color":header.headerFontColor}}>{header.headerTitle}</h3>
    </div>
    <div className="row justify-content-center">
      <h6 className="card-title  magazine-title  " style={{"color":header.headerSubtitleColor , height:"20px !important"}} >{header.subtitle}</h6>
      </div>
    </div>
      </div>      
     </div>
     </div>}

<div className='mt-4'>
    <h1 className='fontmaaray'> ترقبو قائمه الأسعار الجديده  </h1>
</div>
<div className='mt-4'>
<RecordView 
Recfile={Recfile}
setRecfile={props.setRecfile}

CommentNomagazine={CommentNomagazine}
setCommentNomagazine={props.setCommentNomagazine}

></RecordView>
</div>
<div className='container-fluid fontmaaray mt-3'>
  

        <div className='row justify-content-center'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
         <div className='row justify-content-center'id='maindiv'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <FaRegUser  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center mt-1'>بيانات الشخصية</h1> 
         

         
            </div>
    </div>
    <form className='container-fluid' /*</div>onSubmit={handleSubmit}*/>
    <div className='row'> 
    <div>
      
  {/*  <div class="form-group">
    <input
          id="first_name"
          name="first_name"
          type="text"
          onChange={event => setFirstName(event.target.value)}
          value={firstName}
        />
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />

</div>*/}

         <div className="form-group">
    <input type="text" className="form-control mt-3 " style={{'font-size': 'x-large'}}  id='phone'   name='phone' value={phone} onChange={handleChangephone}   placeholder="رقم موبيل " ></input>
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 "style={{'font-size': 'x-large'}} placeholder="الاسم" id='name' value={name}  name='name' onChange={handleChangename}></input>
    {/* <input type="text" className=" inputshow" value={comment} name='comment'  onChange={handleChangecomment}></input> */}
    <input
    className='inputshow'
        type="text"
        id="comment"
        name="comment"
        onChange={handleChangecomment}
        value={comment}
      />

  </div>
  <div className="form-group ">
  <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
           <div className='row mt-2'> 
  
         <input
         onChange={evt => InputChange(evt.target.value)} 
         
         className="mt-2 col-lg-11 fs-5  form-control text-area-width " type='text'  placeholder="العنوان" 
        value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>
        <button if onClick={getCurrentLocation} disabled={loading} className='btn btn-warning col-sm-1 btnwidth'>
          <div className='text-white'>
          <IoLocation className='fs-5 text-center '></IoLocation>
          <p>مكاني</p>
          </div> 
        </button>
        </div>
        

      )}
    </CurrentLocation>
  
  {/* <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
           <div className='row mt-2'> 
  
         <input
         onChangoe={evt => InputChange(evt.target.value) } 
         className="col-lg-11   form-control text-area-width" style={{'width': '80%','margin-right': '1%' ,'font-size': 'x-large'}} type='text'  placeholder="العنوان" 
        value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>

        <button if onClick={getCurrentLocation}  disabled={loading} style={{'width':'17%','margin-right':'1%','margin-top':'0px' }} className='btn btn-warning col-sm-1 btnwidth '>
          <div className='text-white mt-1'>
          <IoLocation className='text-center fs-2 ' style={{'width':'100%'}}></IoLocation>
           <p className=' fs-4'>مكاني</p>
          </div> 
        </button>
        </div>
        

      )}
    </CurrentLocation> */}

 
  </div>
  <div className="form-group">
    
    <input type="email" className="form-control mt-2 " style={{'font-size': 'x-large'}} placeholder="اسم المحل" onChange={handleChangeemail} value={email} id='email' name='email'></input>
  </div>
  <div className='row mt-3'>
  
 
  {isDesktopOrLaptop &&
  <div className='row'>
    <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%','margin-right': '-120px'}} ref={ref} id="rememberMe"></input>
  </div>
   <div className=' col-6'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success' style={{'margin-right': '-306px'}}  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div>
   }
   {isTabletOrMobile &&
   <div className='row'>
     <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%'}} ref={ref} id="rememberMe"></input>
  </div>
    <div className='col-9'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success'  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div> }
   

  </div>

  {/* <div className='row  justify-content-center mt-2 '>
  <input value={TimeDelivery}  onChange={handleChangedate} id='date' name='date' className='hidedeliveredinput'></input>
    <button style={{'width':'45%' ,'height':'10%','margin-left':'4%','background':'red','font-weight': 'bold'}} className=' btn text-white ' id='delived' type="button" onClick={handlebchangetobtnnow} >توصيل </button>
    <button  style={{'width':'45%','margin-left':'2%','background':'#696969'}} onClick={handlebchangetobtn2} id='hand' className='btn text-white' type="button" value='1'>إستلام </button>

    </div> */}
  <hr></hr>
{/* 
  <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <GiDeliveryDrone  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>ميعاد التوصيل  </h1> 
         

         
            </div>
    </div>
  
  <div className='row mt-3'>



<div id='displaybtn' className='container-fluid'>

    <div className='row justify-content-center mt-2 '>
  <button style={{'width':'94%','margin-left':'2%','background':'red','font-weight': 'bold' }} className='btn  btn-white text-white' id='now' type="button" onClick={handlebchangetobtn3}>الأن</button>
</div>
<div className='row justify-content-center  mt-2' >
<button style={{'width':'22%','margin-left':'2%','background':'#696969'}}  className='btn btn-white text-white'  id='after2' type="button" onClick={handlebchangetobtn4}>بعد 2 ساعه  </button>

    <button style={{'width':'23%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white'id='tomM' type="button" onClick={handlebchangebtntomAm} >غداً&nbsp;&nbsp; ص . </button>
  <button  style={{'width':'22%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white' id='tomE' type="button" onClick={handlebchangebtntommidAm}>غداً&nbsp;&nbsp;ظ. </button>
  <button style={{'width':'22%','margin-left':'2%','background':'#696969'}} className='btn btn-white text-white' id='tomN' type="button" onClick={handlebchangebtntompm}>غداً&nbsp; &nbsp;م. </button>
</div>
</div>

</div> */}
{/* <hr className='mt-4' ></hr> */}

{/* <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <MdPayment  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>طريقه السداد</h1> 
         

         
            </div>
    </div> */}



<div className='row'>

<div className='col-12'>
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheight" onClick={handleItemSubmit}><FiSend className='fs-1 mx-4 mt-2'>
  
  </FiSend>
  ارسل  </button>
</div>
{/* <div className='col-3'>


<button className='btn btn-warning mt-3 fs-1 w-100 text-white  submitheight'   onClick={showprint}>print</button>
</div> */}

 
 
    </div>
  </div>
  </div>
 
</form>

</div>

</div>
    </div>


</div>

</div>

</div>
<div className='container-fluid mt-4'>
            <div className="container-fluid">
       {header && 
         <div className='row' style={{'backgroundColor':header.footerBackground }}>
       <div className='col-12 fs-1 '>
         <h3 className='mt-1 fontmaaray' style={{'color':header.footerFontColor}}>{header.footerTitle}</h3>
        </div>
        <div className="row justify-content-center mt-2">
       {header.magazineDefaultFooterItems.map((footer, index) => (
        <div className="col-2 col-lg-2 col-sm-4"style={{'margin-bottom':'15px'}}>
        <a href={footer.url} className='w-100' target="_blank">
         <img src={footer.image} style={{width:'30px'}} ></img>  
         </a>    
         </div>
       ))}
       </div>
        </div> 
       }
 <div className='col-11' >
 <div className='row' >
<div className='col-lg-2 col-5' style={{marginLeft:"4%",border:"1px solid lightgray",borderRadius:"6px",color:"white",background:"red"}} onClick={videoload}>
<div className='d-flex' style={{justifyContent:"center",marginTop:"8%"}}>
<div className='col-lg-9 col-5'>
<p className='fontmaaray' style={{fontSize:"19px"}}>شرح </p>
</div>
<div className='col-lg-3 col-3 p-0'>
<AiFillPlayCircle  style={{fontSize:"35px",color:"white"}}></AiFillPlayCircle>

</div>

</div>
</div>
<div className='col-lg-7 col-1'>

</div>
<div className='col-lg-2 col-5' style={{border:"1px solid lightgray",color:"white",borderRadius:"6px",background:"#0d6efd"}} onClick={openModalofshare}> 
<div className='row'>

<div className='col-6'>
<p className='fontmaaray' style={{marginTop:"23%",textAlign:"right",fontSize:"19px"}}>
  مشاركه
</p>
</div>
<div className='col-6'>
<HiShare className='' style={{marginTop:"21%",fontSize:"30px"}}> </HiShare>

</div>

</div>
</div>
</div>
 </div>



    <div className="footer-- text-center  d-flex justify-content-center align-items-center ">
          <div className='pt-1'>
          <img src={tijary} className='mx-1' style={{width:"80px"}} alt=""  onClick={openModal} />
          </div>
         

        </div>
      </div>

      <div className="modal-yasser  d-none d-flex justify-content-center  align-items-center" >
        <div className="modal-dialog  modal-dialog-centered" >
          <div className="modal-content pb-3 ">
            <div className="modal-header">
              <div className="d-flex justify-content-center" style={{ width: "95%" }}>
                <h1 className="modal-title fs-2" style={{ marginTop: "10px", fontSize: "18px", color: "rgb(25, 135, 84)", fontWeight: "bold", textShadow: "rgb(191, 191, 191) 2px 4px 4px" }}>
                tijary عن 
                </h1>
              </div>
              <div className="">
                <button type="button" className="btn-close btn " onClick={closeModal}></button>
              </div>
            </div>
            <div className="modal-body">
             <div className="d-flex flex-column justify-contnent-center align-items-center">
             <p className='text-center fontmaaray'>نظام متكامل للأداره الشاملة لمحلات البيع و متطلبات عملائهم من خلال مجموعه من التطبيقات وتكنولوجيا اداره المعلومات</p>
         <p className='text-center fontmaaray'>يقوم النظام باداره محلات البيع بما يتطلب من زياده الأرباح والتوفير وتقليص الخسائر </p>
         <p className='text-center fontmaaray mb-0'>يتركز علي ارسال التفوق الاداري و انجاح العمليه التجاريه سواء اونلاين او اوف لاين للانترنت المحل</p>
             </div>
            </div>
            <div className="modal-footer d-flex justify-content-around border-0">
            <a className="col-2  p-2 fs-2" href="https://wa.me/201118118343" target="_blank">
                <i style={{ color: '#27b459'  , fontSize:"1.2rem"}}>
                 {/* <img className='w-50' src={whats}/> */}
                 <WhatsApp color="success"
                 sx={{ fontSize: 40 }}></WhatsApp>
                     </i>
              </a>
              <a className="text-decoration-none" href="mailto:Support@tijary.store">
                <button type="button" className="btn p-2 px-3 " style={{ backgroundColor: "#F56902", color: "#fff" }}>
                <EmailIcon className='mx-1'></EmailIcon>
                 ارسل استفسارك 
                </button>
              </a>
             
            </div>
            <div className="d-flex flex-column mt-2 ">
          <p className="mb-0  text-muted mx-1" style={{ fontSize: "0.6rem" }}>
            Developed by
          </p>
          
          <a className="text-decoration-none mb-2 " href="http://www.bedab.com/contact-arabic.html">
              <img src={bedab} className=" " style={{ width: "70px" }} alt="" />
            </a>
          
          </div>
          </div>
        </div>
        </div>

        <div className="modalshare  d-none d-flex justify-content-center  align-items-center" >
        <div className="modal-dialog  modal-dialog-centered" >
          <div className="modal-content pb-3 ">
            <div className="modal-header">
            <div className="">
                <button type="button" className="btn-close btn btn-danger" onClick={closeModalofshare}></button>
              </div>
              <div className="d-flex justify-content-center" style={{ width: "95%" }}>
                <h1 className="modal-title fs-2 fontmaaray" style={{ marginTop: "10px", fontSize: "18px", color: "rgb(25, 135, 84)", fontWeight: "bold", textShadow: "rgb(191, 191, 191) 2px 4px 4px" }}>
               شارك 
                </h1>
              </div>
            
            </div>
            <div className="modal-body">
             <div className="row justify-content-center">
         <div className='col-4'>
         <FacebookShareButton
        url={`${url}`}
        quote={'Dummy text!'}
        hashtag="مجله فبصل"
      >
        <FacebookIcon size={50} round />
      </FacebookShareButton>
         </div>
<div className='col-4'>
<TwitterShareButton
  url={`${url}`}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"
>
  <TwitterIcon size={50} round />
</TwitterShareButton>
</div>
<div className='col-4'>
<WhatsappShareButton
  url={`${url}`}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"

>
<WhatsappIcon size={50} round />

</WhatsappShareButton>
</div>

{/* <div className='col-2'>
<FacebookMessengerShareButton
  url={'https://magala.zaherdairy.com/'}
  quote={'Dummy text!'}
  hashtag="مجله فبصل"

>
<FacebookMessengerIcon size={32} round />

</FacebookMessengerShareButton>

</div> */}
             </div>
             <div className='row mt-3' style={{justifyContent:"center"}}>
<div className='col-11'>
<div className='row'>


<div className='col-12'>

<button className='btn btn-success w-100 '  onClick={() =>  navigator.clipboard.writeText(`${url}`)}


>
نسخ الرابط <AiFillCopy></AiFillCopy>
</button>
</div>
</div>
</div>
             </div>
            </div>
      
       
          </div>
        </div>
        </div>
        </div>


        </div>
    )
}
export default Nomagazine